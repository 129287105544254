<template>
  <div 
    :class="'background ' + animation"
  >
  </div>
  <div v-if="scanFinished && !showResearchContent && !showResult">
    <div class="ornament-1 ornament"></div>
    <div class="ornament-2 ornament"></div>
  </div>
</template>

<script>
export default {
  setup() {
    
  },
  data() {
    return {
      animation: ''
    }
  },
  watch: {
    showModal(to, from) {
      if (!this.scanFinished && this.state !== 'measuring') {
        if (to && !from) {
          this.animation = 'userInputEnterAnimation';
        } else if (!to && from) {
          this.animation = 'userInputLeaveAnimation';
        }
      } 
    },
    scanFinished(to, from) {
      if (to && !from) {
        this.animation = 'completedEnterAnimation';
      } 
    },
    showResult(to, from) {
      if (this.scanFinished) {
        if (to && !from) {
          this.animation = 'resultEnterAnimation';
        }
      }
    },
    state(to, from) {
      if (to == 'measuring' && from == 'idle') {
        this.animation = 'hide';
      }
    },
    experienceAgain: {
      handler: function(val) {
        if (!val.showResearchContent && !val.showResult && !val.scanFinished) {
          this.animation ='researchLeaveAnimation';
        }
      },
      deep: true
    }
  },
  computed: {
    experienceAgain() {
      const {showResearchContent, showResult, scanFinished} = this;
      return {showResearchContent, showResult, scanFinished};
    }
  },
  props: {
    showModal: Boolean,
    scanFinished: Boolean,
    state: String,
    showResearchContent: Boolean,
    showResult: Boolean
  }
}
</script>

<style scoped>
.background {
  width: 1136px;
  height: 1136px;
  background: radial-gradient(80.11% 80.11% at 21.85% 11.94%, rgba(0, 176, 141, 0.6) 0%, rgba(61, 126, 194, 0.6) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6px);
  border-radius: 50%;
  z-index: 1;
  position: absolute;
  bottom: 1150px;
}

.hide {
  animation: hide-background .5s ease 1;
  animation-fill-mode: forwards;
}

@keyframes hide-background {
  0% {transform: scale(1.61); transform-origin: center;}
  100% {transform: scale(0); transform-origin: center;}
}

.userInputEnterAnimation {
  animation: input-enter-animation 1s ease 1;
  animation-fill-mode: forwards;
}

@keyframes input-enter-animation {
  0% {transform: scale(0.9); transform-origin: center;}
  10% {transform: scale(1); transform-origin: center;}
  90% {transform: scale(1.65); transform-origin: center;}
  100% {transform: scale(1.61); transform-origin: center;}
}

.userInputLeaveAnimation {
  animation: input-leave-animation 1s ease 1;
  animation-fill-mode: forwards;
}

@keyframes input-leave-animation {
  0% {transform: scale(1.61); transform-origin: center;}
  10% {transform: scale(1.65); transform-origin: center;}
  90% {transform: scale(0.9); transform-origin: center;}
  100% {transform: scale(1); transform-origin: center;}
}

.completedEnterAnimation {
  animation: completed-enter-animation 1s ease 1;
  animation-fill-mode: forwards;
}

@keyframes completed-enter-animation {
  0% {transform: scale(0); transform-origin: center;}
  90% {transform: scale(0.76); transform-origin: center;}
  100% {transform: scale(0.75); transform-origin: center;}
}

.researchLeaveAnimation {
  animation: research-leave-animation 1s ease 1;
  animation-fill-mode: forwards;
}

@keyframes research-leave-animation {
  0% {transform: scale(3.35); transform-origin: center;}
  90% {transform: scale(0.9); transform-origin: center;}
  100% {transform: scale(1); transform-origin: center;}
}

.resultEnterAnimation {
  animation: result-enter-animation 1s ease 1;
  animation-fill-mode: forwards;
}

@keyframes result-enter-animation {
  50% {transform: translateY(325px);}
  70% {transform: translateY(325px) scale(1.61); transform-origin: center;}
  100% {transform: translateY(325px) scale(3.35); transform-origin: center;}
}

.ornament {
  background: radial-gradient(80.11% 80.11% at 21.85% 11.94%, rgba(0, 176, 141, 0.6) 0%, rgba(61, 126, 194, 0.6) 100%);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(6px);
  border-radius: 50%;
  animation: ornament-show 1s ease 1;
  animation-fill-mode: forwards;
}

.ornament-1 {
  position: absolute;
  width: 418px;
  height: 418px;
  left: 1298px;
  top: 1650px;
  z-index: 1;
}

.ornament-2 {
  position: absolute;
  width: 244px;
  height: 243px;
  left: 570px;
  bottom: 1275px;
  z-index: 1;
}

@keyframes ornament-show {
  0% {transform: scale(0); transform-origin: center;}
  100% {transform: scale(1); transform-origin: center;}
}
</style>