<template>
  <div class="overlay">
    <div class="article-position">
      <div class="article-container">
        <div :class="animation">
          <h1 
            v-if="selectedArticle !== 'research_team'"
            class="article-title"
          >
            {{t('research_content.' + selectedArticle + ".title")}}
          </h1>
          <div class="article-text-container">
            <p
              v-if="selectedArticle !== 'research_team' && selectedArticle !== 'awards'"
              v-html="t('research_content.' + selectedArticle + '.content')"
              class="article-content"
            >
            </p>
            <p v-if="selectedArticle == 'awards'" class="article-content">
              <ul>
                <li
                  v-for="award in tm('research_content.awards.content')"
                  :key="award">
                  {{award}}
                </li>
              </ul>
            </p>
            <div v-if="selectedArticle == 'research_team'" class="article-content">
              <div class="research-team-container">
                <div
                  v-for="member in tm('research_content.research_team.content')"
                  :key="member"
                  class="research-team-box"
                >
                  <img :src="require('' + member.image)" alt="">
                  <h2 class="research-team-name">
                    {{member.name}}
                  </h2>
                  <h3 class="research-team-title">
                    {{member.title}}
                  </h3>
                  <p class="research-team-dept">
                    {{member.dept}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="navigation-button">
            <button class="change-article-button" @click="prevArticle">
              <img src="./assets/left-arrow.png" alt="" class="change-article-button-image">
            </button>
            <div class="back-button-group">
              <back-button @onClick="close"/>
              <experience-again-button @click="scanAgain"/>
            </div>
            <button class="change-article-button" @click="nextArticle">
              <img src="./assets/right-arrow.png" alt="" class="change-article-button-image">
            </button>
          </div>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import {useI18n} from 'vue-i18n';
import BackButton from './components/back-button.vue';
import ExperienceAgainButton from './components/experience-again-button.vue';
export default {
  setup() {
    const {t, tm} = useI18n();
    return {
      t,
      tm
    }
  },
  props: {
    selectedArticle: String
  },
  emits: [
    'close',
    'nextArticle',
    'prevArticle',
    'scanAgain'
  ],
  components: {
    BackButton,
    ExperienceAgainButton
  },
  data() {
    return {
      animation: ""
    }
  },
  methods: {
    triggerAnimation() {
      this.animation = 'fade-animation';
      setTimeout(() => {
        this.animation = '';
      }, 1000);
    },
    close() {
      this.$emit('close', '');
    },
    nextArticle() {
      this.$emit('nextArticle');
      this.triggerAnimation();
    },
    prevArticle() {
      this.$emit('prevArticle');
      this.triggerAnimation();
    },
    scanAgain() {
      this.$emit('scanAgain')
    }
  }
}
</script>

<style scoped>
.article-container {
  width: 1995px;
  height: fit-content;
  padding: 110px 170px 160px;

  background: rgba(217, 217, 217);
  border-radius: 60px;
  z-index: 1;
}
.article-content {
  font-style: normal;
  font-weight: 400;
  font-size: 56px;
  line-height: 70px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #000000;
}
.article-position {
  position: absolute;
  top: 496px;
  height: calc(100vh - 496px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.article-text-container {
  max-height: 2200px;
  overflow: scroll;
}
.article-title {
  font-style: normal;
  font-weight: 400;
  font-size: 86px;
  line-height: 108px;
  display: flex;
  align-items: center;
  text-align: justify;

  color: #000000;
  margin-bottom: 100px;
}
.back-button-group {
  display: flex;
  flex-direction: row;
  gap: 56px;
}
.change-article-button {
  background-color: transparent;
}
.change-article-button-image {
  width: 60px;
  height: 60px;
}  
.fade-animation {
  animation: fade .5s ease 1 forwards;
}
@keyframes fade {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
li {
  margin-bottom: 20px;
}
.navigation-button {
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.overlay {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}
.research-team-box {
  width: 490px;
  display: flex;
  flex-direction: column;
}
.research-team-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 90px;
  flex-wrap: wrap;
}
.research-team-dept {
  font-weight: 400;
  font-size: 30px;
  text-align: left;
  line-height: 40px;
  color: #000000;
  margin-bottom: 18px;
}
.research-team-name{
  font-style: normal;
  font-weight: 400;
  font-size: 43px;
  line-height: 54px;
  color: #000000;
  margin: 40px 0;
}
.research-team-title {
  font-style: normal;
  font-weight: 400;
  font-size: 43px;
  text-align: left;
  line-height: 58px;

  color: #000000;
}
ul {
  padding-left: 60px;
}
</style>