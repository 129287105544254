// https://css-tricks.com/building-progress-ring-quickly/

<template>
    <div 
        id="get-vs-button-container"
        class="d-flex progress-circle"
        v-if="startBreathing"
    >
        <div class="description description-text">
            <p>
                {{t('message.scan_30s')}}
            </p>
        </div>
        <div class="my-auto">
            <svg
                class="circle"
                :height="radius * 2 * radiusMultiplier"
                :width="radius * 2 * radiusMultiplier"
            >
                <circle class="progress-background"
                    stroke="#ddd"
                    fill="transparent"
                    :stroke-width="normalizedStroke"
                    :r="normalizedRadius"
                    :cx="radius*radiusMultiplier"
                    :cy="radius*radiusMultiplier"
                />
                <circle class="progress"
                    stroke="#FFF"
                    fill="transparent"
                    :stroke-dasharray="circumference + ' ' + circumference"
                    :style="{ strokeDashoffset }"
                    :stroke-width="normalizedStroke"
                    :r="normalizedRadius"
                    :cx="radius*radiusMultiplier"
                    :cy="radius*radiusMultiplier"
                    stroke-linecap="round"
                    
                />
                <text x="50%" y="50%" text-anchor="middle" fill="#fff" class="countdown-text" dy=".3em">{{timeLeft ? timeLeft.toFixed(0): '0'}}</text>
            </svg>
        </div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import VitalsConfig from '../../plugin/plugin_configuration.js'
import { ref } from "vue";

export default {
    setup() {
        const {t, locale} = useI18n();
        return {
            t,
            locale,
            progress: ref(0),
        }
    },
    props: {
        radius: Number,
        stroke: Number,
        timeLeft: {type: Number, default: null},
        text: String,
    },
    data() {
        var normalizedRadius = this.radius*3-this.stroke;
        const circumference = normalizedRadius * 2 * Math.PI;

        return {
            startBreathing: false,
            normalizedStroke: this.stroke,
            normalizedRadius,
            circumference,
            width: window.innerWidth,
            radiusMultiplier: 1,
        };
    },
    methods: {
        getWidth() {
            this.width = window.innerWidth;
            if (this.width < 2000 && this.width >= 1024) {
                this.normalizedStroke = 15;
                this.radiusMultiplier = 1.25;
            } else if (this.width >= 2000) { 
                this.normalizedStroke = 25;
                this.radiusMultiplier = 2.5
            } else if (this.width < 1024) {
                this.normalizedStroke = 10;
                this.radiusMultiplier = 1;
            }
            this.normalizedRadius = this.radius*this.radiusMultiplier-this.normalizedStroke;
            this.circumference = this.normalizedRadius * 2 * Math.PI;
        },
    },
    created() {
        this.totalTime = VitalsConfig.Plugin.scanTime;
    },
    mounted() {
        this.getWidth();
        window.addEventListener('resize', this.getWidth);
    },
    unmounted() {
        window.removeEventListener('resize', this.getWidth);
    },
    computed: {
        strokeDashoffset() {
            return this.circumference - this.progress / 100 * this.circumference;
        },
        inOutTextStyle() {
            var _in = this.$t('message.breath_in');
            var _out = this.$t('message.breath_out');
            return `--in:"${_in}"; --out:"${_out}";`;
        }
    },
    watch: {
        timeLeft(newValue, oldValue) {
            if(oldValue == null && newValue !== null) {
                this.startBreathing = true;
            }
            if (newValue === null) {
                this.progress = 0;
                this.state = "idle";
                this.startBreathing = false;
            } else {
                this.progress = Math.round(((this.totalTime - this.timeLeft) / this.totalTime)*100.0)
            }
        },
    }
}
</script>


<style scoped>
#get-vs-button-container {
    position: absolute;
    top: 20%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    border-radius: 36px;
    animation: fade-in 1s ease 1 forwards;
}
@keyframes fade-in {
    0% {opacity: 0;}
    100% {opacity: 1;}
}
.d-flex {
    gap: 80px;
}
.description {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1131px;
    width: fit-content;
    max-height: 225px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 100px;
    padding: 40px 90px;
}
.description-text {
    font-weight: 400;
    font-size: 50px;
    text-align: center;

    color: #484848;
}
.countdown-text {
    font-weight: 400;
    font-size: 80px;
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    color: #FFFFFF;
}
.progress {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}
.progress-background {
    opacity: 0.5;
}

</style>