<template>
  <div
    class=""
    id="userProfileInput"
    v-if="!profileFilled && showModal"
  >
    <div class="modal-dialog align-items-center">
      <div class="modal-content enter-input-animation">
        <div class="modal-header px-3">
          <div class="container">
            <div class="question" v-if="!ageChoice && !genderChoice">
              {{t('user_input.question')}}
            </div>
            <div class="question" v-else-if="ageChoice">
              {{t('user_input.select_age')}}
            </div>
            <div class="question" v-else-if="genderChoice">
              {{t('user_input.select_gender')}}
            </div>
          </div>
        </div>
        <div class="modal-body input-container d-flex flex-column align-items-center justify-content-start">
          <div v-if="!ageChoice && !genderChoice" class="d-flex flex-column align-items-center choice-animation">

            <!-- Age -->
            <button class="info-button" @click="showAgeChoice">
              <p v-if="info.age == ''">
                {{t('user_input.age')}}
              </p>
              <p v-else>{{info.age}}</p>
            </button>


            <!-- Gender -->
            <button class="info-button" @click="showGenderChoice">
              <p v-if="info.gender == ''">
                {{t('user_input.gender')}}
              </p>
              <p v-else>
                {{t('user_info.' + info.gender)}}
              </p>
            </button>


            <button type="button" class="mx-auto btn continue-button start-button" :disabled="disabled" @click="updateProfile">
              {{t('button.start')}}
            </button>

            <button type="button" class="mx-auto btn continue-button back-button" @click="closeModal">
              <img src="./assets/left-arrow.png" alt="">
              <p>
                {{t('button.back_to_homepage')}}
              </p>
            </button>
          </div>

          <div v-if="ageChoice && !genderChoice" class="age-choice text-center d-flex flex-column choice-animation">
            <button v-for="(age, index) in age" :key="index" class="age-choice-text" @click="() => setAge(age)">
              {{age}}
            </button> 
          </div>

          <div v-if="genderChoice && !ageChoice" class="choice-animation gender-choice-container">
            <button class="gender-choice" @click="() => setGender('male')">
              {{t('user_info.male')}}
            </button>
            <button class="gender-choice" @click="() => setGender('female')">
              {{t('user_info.female')}}
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "user-profile-input",
  props: {
    form: Object,
    showModal: Boolean
  },
  setup() {
    const { t, locale } = useI18n();
    const {profileFilled, updateProfileFilled} = inject('profileFilled');
    return { 
      profileFilled,
      updateProfileFilled,
      t, locale
    };
  },
  emits: ["updateProfile", "updateModal"],
  mounted() {
    setTimeout(() => {
      this.checkDisabled();
    }, 300);
  },
  components: {},
  computed: {
    age() {
      const age = []
      for (var i of Array(9).keys()) {
        if (i < 2) {
          continue;
        }
        else if ( i == 2) {
          age.push('18-29');
        } 
        else {
          age.push(`${i*10}-${i*10+9}`)
        }
      }
      age.push('≥ 90')
      return age;
    }
  },
  methods: {
    loadForm() {
        this.info = this.form;
        if (this.debug) {
          this.info = {
            name: "DebugUser",
            email: "debug@panoptic.ai", 
            phoneNumber: "12345678",
            birthday: "2019-08-19",
            gender: "male",
            height: 170,
            weight: 70,
            unitSystem: false,
            countries: "Hong Kong",
            smoker: false,
            hypertension: false,
            bloodPressureMedication: false,
            diabetic: "No",
            heartDisease: false,
            depression: false,
          }
        }
    },
    checkDisabled() {
      let check = false;
      Object.keys(this.info).map((data) => {
        if (this.info[data] === null || this.info[data] === "") {
          check = true;
          return;
        }
      });
      this.disabled = check;
    },
    updateProfile() {
      this.$emit("updateProfile", this.info);
      this.closeModal();
    },
    closeModal() {
      this.$emit("updateModal", false);
    },
    showAgeChoice() {
      this.ageChoice = true;
    },
    showGenderChoice() {
      this.genderChoice = true;
    },
    setAge(age) {
      this.info.age = age;
      this.ageChoice = false;
    },
    setGender(gender) {
      this.info.gender = gender; 
      this.genderChoice = false;
    }
  },
  watch: {
    info: {
      deep: true,
      handler: function() {
        this.checkDisabled();
      }
    }
  },
  data() {
    return {
      info: {
        gender: "",
        age: ""
      },
      disabled: true, 
      debug: this.$root.debugMode,
      ageChoice: false,
      genderChoice: false,
    };
  },
};
</script>

<style scoped>
.gender-choice-container {
  margin-top: 200px;
}
.choice-animation {
  animation: fade .75s ease 1 forwards;
}
@keyframes fade {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
.enter-input-animation {
  animation: fade-in-long 1.25s ease 1 forwards;
}

@keyframes fade-in-long {
  0% {opacity: 0;}
  25% {opacity: 0;}
  100% {opacity: 1;}
}

button {
  border: none;
}
#userProfileInput {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 2;
}
.modal-dialog {
  display: flex;
  top: 30%;
  max-width: 1844px;
  height: 1844px;
  width: 100%;
  margin: 0 auto;
  border-radius: 50%;
}

.info-button {
  height: 153px;
  width: 700px;
  font-size: 80px;
  border-radius: 50px;
  margin-bottom: 70px;
  background: #D9D9D9;
  color: #575D5F;
}
/* Modal Content/Box */
.modal-content {
  position: grid;
  margin: 0% auto;
  padding: 5px;
  min-width: 100%;
  height: 75%;
  overflow: hidden;
  border-radius: 24px;
  padding: 0px;
  border: none;
  background-color: transparent;
}
.modal-header {
  display: flex;
  justify-content: center;
  border: none;
  font-size: 18px;
  font-weight: 600;
  padding: 140px 0;

}
.modal-body {
  justify-content: center;
  padding: 2px;
  opacity: 1;
  border-radius: 0px 0px 36px 36px;
}
.continue-button {
  padding: 11.9024px 74.3902px;

  width: 431px;
  height: 112px;

  background: #FFFFFF;
  box-shadow: 0px 5.95122px 5.95122px rgba(0, 0, 0, 0.25);
  border-radius: 59.5122px;
  color: #000000;
  font-weight: 600;
  font-size: 65px;
}
.start-button {
  margin-top: 200px;
}
.back-button {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 48px;
  width: fit-content;
  min-width: 431px;
}
.back-button p {
  margin-bottom: 0%;
}
.title {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.25px;
    text-align: left;
    color: white;
}
.question {
  font-style: normal;
  font-weight: 400;
  font-size: 70px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #FFFFFF;
  text-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08);
}
.age-choice {
  position: absolute;
  font-size: 100px;
  width: 816px;
  height: 100%;

  background: rgba(255, 255, 255, 0.76);
  backdrop-filter: blur(27px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 50px;
  overflow: scroll;
  padding: 58px 0;
}
.age-choice-text {
  padding: 10px 0;
  margin: px 10px;
  font-weight: 400;
  font-size: 84px;
  background: none;
  border: none;

  color: #575D5F;
}
.age-choice-text:focus {
  background: rgba(0, 0, 0, 0.06);
}
.gender-choice {
  width: 479px;
  height: 128px;

  background: #D9D9D9;
  border-radius: 50px;
  font-weight: 400;
  font-size: 80px;
  text-align: center;

  color: #575D5F;
  border: none;
  margin: 0px 30px;
}
p {
  margin-bottom: 0%;
}
</style>


