<template>
  <div class="vitals-box">
    <vital-sign-chart :data="vitalsValue" :textFontSize="textFontSize" :vitalSign="vitalSign"/>
    <div class="vitals-unit">
      {{t('scanning_result.vital_signs.' + vitalSign + '.unit')}}
    </div>
    <div class="vitals-title">
      {{t('scanning_result.vital_signs.' + vitalSign + '.title')}}      
    </div>
  </div>
</template>

<script>
import VitalSignChart from './vital-sign-chart.vue'
import {useI18n} from "vue-i18n"
export default {
  setup() {
    const {t} = useI18n();
    return {
      t
    }
  },
  components: {
    VitalSignChart
  },
  props: [
    'vitalSign',
    'textFontSize',
    'vitalsValue'
  ]
}
</script>

<style scoped>
.vitals-unit {
  font-weight: 700;
  font-size: 62px;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);
  text-align: center;
}
.vitals-title {
  font-weight: 700;
  font-size: 62px;
  margin-top: 30px;

  text-align: center;

  color: #000000;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);
}
</style>