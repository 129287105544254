<template>
  <div class="locale-changer normal d-flex align-items-center flex-column">
    <div
        v-for="(locale, index) in locales"
        :key="index"
        :class="`${index !== locales.length-1 ? 'separator' : ''}`">
      <button
        :class="`option ${$i18n.locale == locale ? 'selected' : ''}`"
        :disabled="$i18n.locale ==  locale"
        @click="$i18n.locale = locale"
      >
        {{ locale }}
      </button>
    </div>
    <!-- <select v-model="$i18n.locale" id="locale-selector" width="50px">
      <option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
    </select> -->
  </div>
</template>

<script>
//import { useI18n } from 'vue-i18n';

export default {
  name: "locale-button",
  setup() {
    //const { t, locale } = useI18n();
    //console.log(t, locale);
  },
  methods: {},
  data() {
    return {
      locales: ['中', 'EN']
    }
  }
};
</script>

<style scoped>
.separator {
  border-bottom: 7.56946px solid #000000;
}
.option {
  filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.2));
  width: 80px;
  height: 100%;
  font-size: 52px;
  font-weight: 700;
  font-family: 'Mulish';
  color: black;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);
  border: none;
  background-color: transparent;
  padding: 30px 0;
}
.selected {
  color: rgba(0, 0, 0, 0.3);
}
</style>
