<template>
    <button 
        ref="button" 
        v-if="state == 'idle' && !showModal"
        :class="'btn button-container ' + animation"
        @click="removeProfile"
        :disabled="!enable"
    >
        <div :class="!enable ? 'disabled' : ''">
            <img src="./../assets/start-button.png" alt="">
            <p class="button-text">
                {{t("start_page.touch")}}
            </p>
            <div class="click-me-container">
                <img src="./../assets/click-me.png" alt="">
                <p class="click-me-text">
                    {{t('start_page.click_me')}}
                </p>
            </div>
        </div>
    </button>
</template>

<script>
import { ref, inject } from 'vue'
import { useI18n } from 'vue-i18n';
// import BIBOCircle from './breathing-circle.vue'
import VitalsConfig from '../../../plugin/plugin_configuration.js'

export default {
    name: 'vital-sign-button',
    emits: ["startMeasurement", "removeProfile", "updateModal"],
    props: {
        radius: Number,
        enable: Object,
        showModal: Boolean
    },
    setup() {
        const { t, locale } = useI18n();
        const {profileFilled, updateProfileFilled} = inject('profileFilled');

        return {
            profileFilled,
            updateProfileFilled,
            t, locale,
            vitalSign: ref(),
            state: ref('idle'),
        }
    },
    data() {
        return {
            top: '',
            animation: ''
        }
    },
    watch: {
        timeLeft(newValue) {
            if (newValue === null) {
                this.progress = 0;
                this.state = "idle";
            } else {
                this.progress = Math.round(((this.totalTime - this.timeLeft) / this.totalTime)*100.0)
            }
        },
        enable(newValue) {
            if (newValue == undefined) {
                this.state = 'idle';
            }
        },
        start(val) {
            if (val) {
                setTimeout(() => {
                    this.getOffsetTop();
                }, 1000);
            }
        }
    },
    created() {
        this.totalTime = VitalsConfig.Plugin.scanTime;
    },
    methods: {
        removeProfile() {
            this.$emit('removeProfile');
            this.$emit('updateModal', true)
        },
        getOffsetTop() {
            if (document.getElementById('start-content')){
                const top = document.getElementById('start-content').clientHeight + document.getElementById('start-content').offsetTop;
                if (top) {
                    this.top = top+'px';
                } else {
                    this.top = '200px'
                }
            }
        },
    },  
    mounted() {
        setTimeout(() => {
            this.getOffsetTop()
        }, 2000);
        window.addEventListener('resize', this.getOffsetTop);
        this.animation = 'enterAnimation'
    },
    unmounted() {

        window.removeEventListener('resize', this.getOffsetTop);

    },
    computed: {
        isDisable() {
            if (this.enable !== null & this.enable !== undefined) {
                return true
            }
            return false
        },
        start() {
            return this.state == 'idle' && !this.showQuiz && !this.showModal
        }
    },
}
</script>

<style scoped>
.btn-circle {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    padding: 10px 16px;
    font-size: 16px;
    text-align: center;
}

.button-text {
    font-style: normal;
    font-weight: 400;
    font-size: 70px;
    text-align: center;

    color: #FFFFFF;
}

.button-container {
    width: 1136px;
    height: 1136px;

    /* ust */

    /* background: radial-gradient(80.11% 80.11% at 21.85% 11.94%, rgba(0, 176, 141, 0.6) 0%, rgba(61, 126, 194, 0.6) 100%); */
    /* border: 1px solid rgba(255, 255, 255, 0.3); */
    backdrop-filter: blur(6px);
    border-radius: 50%;
    z-index: 3;
}

.click-me-container {
    position: absolute;
    bottom: -10%;
    right: 20%;
}

.click-me-text {
    font-style: normal;
    font-weight: 400;
    font-size: 60px;
    line-height: 80px;
    text-align: center;

    color: #FFFFFF;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.enterAnimation {
    animation: fade-in 2s ease 1 forwards;
}

@keyframes fade-in {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

#get-vs-button {
    width: 480px;
    height: 240px;
    background: #333D47;
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
    border-radius: 240px;
    color: white;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 0.231689px;
    padding: 0;
}
.disabled{
    opacity: 0.4;
}
#get-vs-button-container {
    position: absolute;
    top: 11%;
    width: 75%;
}
.progress-circle {
    background-color: #FFFFFFB2;
    padding: 80px;
    border-radius: 36px;
}
</style>