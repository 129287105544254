<template>
  <div class="result-container">
    <div :class="'content ' + animation">
      <h1 class="title">
        {{t('scanning_result.title')}}
      </h1>
      <div class="result" v-if="vitalSign">
        <div class="general-wellness-container">
          <general-wellness-chart :generalWellness="generalWellnessData"/>
          <div class="general-wellness-text">
            {{generalWellnessText}}
          </div>
        </div>
        <div class="vitals-container">
          <vitals-box 
            :textFontSize="100" 
            :vitalSign="'heart_rate'" 
            :vitalsValue="vitalSign.heartRate"
          />
          <div class="facial-skin-age-container">
            <div class="facial-skin-age-value">
              {{vitalSign.facialSkinAge.toFixed(0)}}
            </div>
            <div class="facial-skin-age-title">
              {{t('scanning_result.vital_signs.facial_skin_age.title')}}
            </div>
          </div>
          <vitals-box 
            :textFontSize="100" 
            :vitalSign="'spo2'" 
            :vitalsValue="vitalSign.spo2"
          />
          <vitals-box 
            :textFontSize="60" 
            :vitalSign="'blood_pressure'" 
            :vitalsValue="vitalSign.bloodPressure"
          />
          <vitals-box 
            :textFontSize="130" 
            :vitalSign="'stress'" 
            :vitalsValue="vitalSign.stress"
          />
          <vitals-box 
            :textFontSize="100" 
            :vitalSign="'respiratory_rate'" 
            :vitalsValue="vitalSign.respiratoryRate"
          />
        </div>
      </div>
      <view-research-content-button @onClick="navigateToResearchContent"/>
      <experience-again-button @onClick="scanAgain" :style="{marginTop: '50px'}"/>
    </div>
  </div>
</template>

<script>
import ViewResearchContentButton from "./components/view-research-content-button.vue";
import ExperienceAgainButton from './components/experience-again-button.vue';
import GeneralWellnessChart from './components/general-wellness-chart.vue';
import VitalsBox from './components/vitals-box.vue';
import {useI18n} from 'vue-i18n';
export default {
  setup() {
    const {t} = useI18n();
    return {
      t
    }
  },
  emits: [
    'updateShowResearchContent',
    'updateShowResult',
    'removeProfile'
  ],
  props: {
    vitalSign: Object,
    from: String,
  },
  components: {
    ExperienceAgainButton,
    ViewResearchContentButton,
    GeneralWellnessChart,
    VitalsBox
  },
  methods: {
    navigateToResearchContent() {
      this.$emit('updateShowResearchContent', true)
    },
    scanAgain() {
      this.$emit('updateShowResult', false, 'result');
      this.$emit('removeProfile');
    }
  },
  computed: {
    generalWellnessText() {
      if (this.vitalSign.generalWellness >= 80) {
        return this.t('scanning_result.condition_text.excellent');
      } else if (this.vitalSign.generalWellness < 80 && this.vitalSign.generalWellness > 40) {
        return this.t('scanning_result.condition_text.fair');
      } else {
        return this.t('scanning_result.condition_text.needs_improvement');
      }
    }
  },
  mounted() {
    if (this.from == 'research') {
      this.animation = 'back-from-research';
      setTimeout(() => {
        for (var i = 0; i < this.vitalSign.generalWellness; i++) {
          this.generalWellnessData = this.generalWellnessData + 1;
        }
      }, 500);
    } else if (this.from == 'completed_button') {
      setTimeout(() => {
        for (var i = 0; i < this.vitalSign.generalWellness; i++) {
          this.generalWellnessData = this.generalWellnessData + 1;
        }
      }, 1750);
      this.animation = 'first-time-show';
    }
  },
  data() {
    return {
      animation: '',
      generalWellnessData: 0
    }
  }
}
</script>

<style scoped>
.back-from-research {
  animation: fade-in 1s ease 1 forwards;
}
@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
.content {
  position: absolute;
  top: 0%;
  padding: 240px 270px;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.facial-skin-age-container {
  width: 33.33%;
  display: flex;
  justify-content: center;
  gap: 120px;
  align-items: center;
  flex-direction: column;
  height: 540px;
}
.facial-skin-age-title{
  font-weight: 700;
  font-size: 62px;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);

  color: #000000;
}
.facial-skin-age-value {
  font-weight: 700;
  font-size: 130px;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);

  color: #000000;
}
.first-time-show {
  animation: fade-in-wait 2s ease 1 forwards;
}
@keyframes fade-in-wait {
  0% {opacity: 0;}
  60% {opacity: 0;}
  100% {opacity: 1;}
}
.general-wellness-container {
  margin-top: 143px;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);
  margin-bottom: 80px;
  text-align: center;
}
.general-wellness-text {
  font-weight: 700;
  font-size: 62px;
  text-align: center;
  color: #000000;
  margin-top: 111px;
}
.result-container {
  position: absolute;
  top: 496px;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 2;
}
.title {
  font-weight: 700;
  font-size: 140px;
  text-align: center;

  color: #000000;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);
}
.vitals-box {
  width: 33.33%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 570px;
}
.vitals-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
}
</style>