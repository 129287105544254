<template>
  <div class="completed-button">
    <button class="btn button" @click="updateShowResult">
      <p class="button-text">
        {{t("button.scan_complete")}}
      </p>
    </button>
    <div class="visual-hint">
      <img src="./../assets/click-result.png" alt="">
      <p class="click-text">
        {{t('message.see_result')}}
      </p>
    </div>
  </div>
</template>

<script>
import {useI18n} from "vue-i18n"
export default {
  setup() {
    const {t} = useI18n();
    return {
      t
    }
  },
  emits: [
    'updateShowResult'
  ],
  methods: {
    updateShowResult() {
      this.$emit('updateShowResult', true, 'completed_button')
    }
  }
}
</script>

<style scoped>
.button {
  width: 855px;
  height: 855px;
  border-radius: 50%;
  z-index: 3;
}

.button-text {
  font-weight: 400;
  font-size: 100px;
  text-align: center;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);
}

.click-text {
  font-weight: 400;
  font-size: 90px;
  /* or 140% */

  text-align: center;

  color: #FFFFFF;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.completed-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 1674px;
}

.visual-hint {
  position: relative;
  top: -87px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>