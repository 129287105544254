<template>
  <button class="research-button" @click="onClick">
    <p class="research-button-text">
      {{t('button.view_research_content')}}
    </p>
    <img src="./../assets/right-arrow.png" alt="">
  </button>
</template>

<script>
import {useI18n} from "vue-i18n"
export default {
  setup() {
    const {t} = useI18n();
    return {t}
  },
  emits: [
    'onClick'
  ],
  methods: {
    onClick() {
      this.$emit('onClick');
    }
  }
}
</script>

<style scoped>
.research-button {
  width: fit-content;
  height: 91.35px;
  font-weight: 600;
  font-size: 48.5632px;
  /* identical to box height */

  background: #FFFFFF;
  box-shadow: 0px 5.92647px 5.92647px rgba(0, 0, 0, 0.25);
  border-radius: 59.2647px;
  color: #000000;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 60px;
}
.research-button-text {
  margin-right: 36px;
  margin-bottom: 0%;
}
</style>