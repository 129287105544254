<template>
    <img v-if="fulfilled" src="./assets/checkmark.svg">
    <img v-else src="./assets/crossmark.svg">
</template>

<script>

export default {
    name: 'condtion-icon',
    props: {
        fulfilled: { type:Boolean, default:false }
    },
    setup() {
        
    },
}
</script>

<style scoped>
img {
    width: 46px;
    height: 35px;
    margin: auto 10px;
}
@media (max-width: 2000px) {
    img {
        width: 30px;
        height: 30px;
    }
}
</style>