<template>
  <button class="view-more-button" @click="viewMore">
    <img src="./../assets/bottom-arrow.png" alt="">
    <p class="view-more-button-text">
      {{t('button.view_more')}}
    </p>
  </button>
</template>

<script>
import {useI18n} from "vue-i18n";
export default {
  setup() {
    const {t} = useI18n();
    return {t}
  },
  emits: [
    'viewMore'
  ],
  props: {
    selectedArticle: String
  },
  methods: {
    viewMore() {
      this.$emit('viewMore', this.selectedArticle);
    }
  }
}
</script>

<style scoped>
.view-more-button {
  align-self: flex-end;
  width: 247.52px;
  height: 64.51px;
  background: #FFFFFF;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  display: flex;
  gap: 23px;
  justify-content: center;
  align-items: center;
}
.view-more-button-text {
  font-style: normal;
  font-weight: 400;
  font-size: 31.2084px;
  line-height: 45px;
  display: flex;
  margin: 0%;
  align-items: center;

  color: #000000;
}
</style>