import { unmarshall } from "@aws-sdk/util-dynamodb";
import axios from "axios";

const headers = {
  "X-Api-Key": "gRDoEz2Hdw7xptQYQyDgw2ndp6L7Zqvq12Fx7Nxr",
};

const readDataFromDb = async (params) => {
  try {
    const results = await axios.post(
      "https://aws-backend.panoptic.ai/db/",
      {
        type: "read",
        params: params,
      },
      { headers: headers }
    );
    return unmarshall(results.data.Item);
  } catch (err) {
    console.log(`%c${err}`, 'color:red');
    console.log("params = ", params)
    return;
  }
};

const updateItemInDb = async (params) => {
  try {
    await axios.post(
      "https://aws-backend.panoptic.ai/db/",
      {
        type: "update",
        params: params,
      },
      { headers: headers }
    );
    return true;
  } catch (err) {
    console.log(`%c${err}`, 'color:red');
    console.log("params = ", params)
    return false;
  }
};

const queryDb = async (params) => {
  try {
    const results = await axios.post(
      "https://aws-backend.panoptic.ai/db/",
      {
        type: "query",
        params: params,
      },
      { headers: headers }
    );

    let unpackedResults = [];
    for (let i = 0; i < results.data.Items.length; i++) {
      unpackedResults.push(unmarshall(results.data.Items[i]));
    }
    return unpackedResults;
  } catch (err) {
    console.log(`%c${err}`, 'color:red');
    console.log("params = ", params)
    return;
  }
};

export default { readDataFromDb, updateItemInDb, queryDb };
