<template>
  <button class="back-button" @click="onClick">
    <img src="./../assets/left-arrow.png" alt="">
    <p class="back-button-text">
      {{t('button.back')}}
    </p>
  </button>
</template>

<script>
import {useI18n} from "vue-i18n";
export default {
  setup() {
    const {t} = useI18n();
    return {
      t,
    }
  },
  emits: [
    'onClick'
  ],
  methods: {
    onClick() {
      this.$emit('onClick')
    }
  }
}
</script>

<style scoped>
.back-button {
  width: 310.21px;
  height: 88.54px;

  font-style: normal;
  font-weight: 600;
  font-size: 48.5632px;
  line-height: 61px;
  /* identical to box height */

  background: #FFFFFF;
  box-shadow: 0px 5.92647px 5.92647px rgba(0, 0, 0, 0.25);
  border-radius: 59.2647px;
  color: #000000;

  display: flex;
  justify-content: center;
  align-items: center;
}
.back-button-text {
  margin-bottom: 0;
  margin-left: 33px;
}
</style>