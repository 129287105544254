<template>
  <svg
      class="circle"
      :height="410"
      :width="410"
  >
      <circle class="wellness-background"
          stroke="#ddd"
          fill="transparent"
          :stroke-width="45"
          :r="354/2"
          :cx="410/2"
          :cy="410/2"
      />
      <circle class="wellness"
          stroke="#83CFC0"
          fill="transparent"
          :stroke-dasharray="354*Math.PI + ' ' + 354*Math.PI"
          :style="{ strokeDashoffset }"
          :stroke-width="45"
          :r="354/2"
          :cx="410/2"
          :cy="410/2"
          stroke-linecap="round"
          
      />
      <text 
        x="50%" 
        y="50%" 
        text-anchor="middle" 
        fill="#000" 
        class="general-wellness-text"
        dy=".3em"
      >
          {{generalWellness ? generalWellness.toFixed(0): '0'}}
      </text>
  </svg>
</template>

<script>
export default {
  setup() {
    
  },
  props: {
    generalWellness: Number
  },
  computed: {
    strokeDashoffset() {
        return 354*Math.PI - this.generalWellness / 100 * 354*Math.PI;
    },
  },
}
</script>

<style scoped>
.general-wellness-text {
  font-weight: 700;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);
  font-size: 144px;
  text-align: center;

  color: #000000;
}
.wellness {
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
</style>