<template>
  <svg ref="vitalsChart" height="270" width="454"></svg>
</template>

<script>
import * as d3 from "d3";
import { ref } from "vue";
export default {
  setup() {
    const vitalsChart = ref(null);

    return {
      vitalsChart,
    };
  },
  props: ["data", "textFontSize", "vitalSign"],
  async mounted() {
    const vis = d3.select(this.vitalsChart);

    var pi = Math.PI;
    var breakPoints = 180;
    var angleArr = [];
    var arcArr = [];

    const gradientData = this.gradient[this.vitalSign];
    const offsets = gradientData.offset.map((offset) => {
      return (
        ((gradientData.max - offset) / (gradientData.max - gradientData.min)) *
          pi -
        pi / 2
      );
    });

    offsets.sort((a, b) => a - b);

    var colorScale = d3
      .scaleLinear()
      .domain(offsets)
      .range(this.gradient[this.vitalSign].color);

    var angleScale = d3
      .scaleLinear()
      .range([-pi / 2, pi / 2])
      .domain([0, breakPoints - 1]);

    var prevAngle = -pi / 2;
    for (var i = 0; i < breakPoints; i++) {
      angleArr[i] = angleScale(i);
      var subArc = {};
      subArc["start"] = prevAngle;
      subArc["end"] = angleArr[i];
      prevAngle = angleArr[i];
      arcArr.push(subArc);
    }

    var point;

    if (this.vitalSign == "blood_pressure") {
      const bp = this.data.split("/");
      const bpSystolic = Number(bp[0]);
      const bpDiastolic = Number(bp[1]);

      if (
        bpSystolic >= 70 &&
        bpSystolic < 90 &&
        bpDiastolic >= 40 &&
        bpDiastolic < 60
      ) {
        point = 0; //low bp
      } else if (bpSystolic < 120 && bpDiastolic < 80) {
        point = 70; //ideal bp
      } else if (bpSystolic < 140 && bpDiastolic < 90) {
        point = 135; //high normal
      } else {
        point = 179; //high
      }
    } else {
      point = (
        ((this.data - gradientData.min) /
          (gradientData.max - gradientData.min)) *
        180
      ).toFixed(0);
    }

    var angle;

    if (point < 0) {
      angle = 0;
    } else if (point > 179) {
      angle = 179;
    } else {
      angle = point;
    }


    const trianglePoint = {
      x: 200 * Math.sin(angleArr[angle]),
      y: -(200 * Math.cos(angleArr[angle])),
    };
    const triangleOffsets = [
      {
        x: trianglePoint.x + 35 * Math.cos(angleArr[angle] + (4 * pi) / 3),
        y: trianglePoint.y + 35 * Math.sin(angleArr[angle] + (4 * pi) / 3),
      },
      {
        x: trianglePoint.x + 35 * Math.cos(angleArr[angle] - pi / 3),
        y: trianglePoint.y + 35 * Math.sin(angleArr[angle] - pi / 3),
      },
    ];

    d3.arc()
      .outerRadius(364 / 2)
      .innerRadius((0.77 * 364) / 2)
      .startAngle(-(pi / 2)) //converting from degs to radians
      .endAngle(pi / 2); //just radians

    vis
      .append("path")
      .attr("class", "arrow")
      .attr(
        "d",
        `M${trianglePoint.x} ${trianglePoint.y}L${triangleOffsets[0].x} ${triangleOffsets[0].y}L${triangleOffsets[1].x} ${triangleOffsets[1].y}L${trianglePoint.x} ${trianglePoint.y}Z`
      )
      .attr("stroke", "#000")
      .attr("transform", "translate(" + 454 / 2 + ", 250)");

    vis
      .selectAll("arcs")
      .data(arcArr)
      .enter()
      .append("path")
      .attr("class", "arc")
      .attr("d", function (d) {
        return d3
          .arc()
          .outerRadius(364 / 2)
          .innerRadius((0.77 * 364) / 2)
          .startAngle(d.end)
          .endAngle(d.start)();
      })
      .attr("fill", function (d, i) {
        return colorScale(angleArr[i]);
      })
      .attr("transform", "translate(" + 454 / 2 + ", 250)");

    vis
      .append("text")
      .text(typeof this.data == "number" ? this.data.toFixed(0) : this.data)
      .attr("class", "value font-size-" + this.textFontSize)
      .attr("y", 220)
      .attr("x", 454 / 2)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle");
  },
  data() {
    return {
      gradient: {
        heart_rate: {
          offset: [42, 50, 75, 83],
          color: ["#EA6B77", "#83CFC0", "#83CFC0", "#EA6B77"],
          min: 42,
          max: 83,
        },
        spo2: {
          offset: [0, 90, 100],
          color: ["#EA6B77", "#EA6B77", "#83CFC0"],
          min: 0,
          max: 100,
        },
        blood_pressure: {
          offset: [0, 35, 80, 100],
          color: ["#EA6B77", "#83CFC0", "#83CFC0", "#EA6B77"],
          min: 0,
          max: 100,
        },
        stress: {
          offset: [0, 2, 5],
          color: ["#83CFC0", "#83CFC0", "#EA6B77"],
          min: 0,
          max: 5,
        },
        respiratory_rate: {
          offset: [6, 9, 20, 24],
          color: ["#EA6B77", "#83CFC0", "#83CFC0", "#EA6B77"],
          min: 6,
          max: 24,
        },
      },
    };
  },
};
</script>

<style scoped>
svg :deep() .value {
  font-weight: 700;
  text-align: center;

  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);
  color: #000000;
}

svg :deep() .font-size-60 {
  font-size: 60px;
}

svg :deep() .font-size-100 {
  font-size: 100px;
}

svg :deep() .font-size-130 {
  font-size: 130px;
}
</style>