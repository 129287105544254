<template>
  <div class="result-container">
    <div class="content">
      <h1 class="title">
        {{t('research_content.title')}}
      </h1>
      <div class="articles">
        <div class="w-100 column">
          <div class="articles-box" :style="{height: '730px'}">
            <h2>
              {{t('research_content.global_challenge.title')}}
            </h2>
            <p 
              class="box-content" 
              v-html="t('research_content.global_challenge.content')"
              :style="{height: '363px'}"
            >
            </p>
            <view-more-button 
              @viewMore="changeSelectedArticle" 
              :selectedArticle="'global_challenge'"
            />
          </div>
          <div class="articles-box" :style="{height: '632px'}">
            <h2>
              {{t('research_content.awards.title')}}
            </h2>
            <p
              :style="{height: '340px'}"
              class="box-content" 
            >
            <ul>
              <li
                v-for="award in tm('research_content.awards.content')"
                :key="award">
                {{award}}
              </li>
            </ul>
            </p>
            <view-more-button 
              @viewMore="changeSelectedArticle" 
              :selectedArticle="'awards'"
            />
          </div>
          <div class="articles-box" :style="{height: '730.97px'}">
            <h2>
              {{t('research_content.research_team.title')}}
            </h2>
            <div class="research-team-summary">
              <img :src="require('' + t('research_content.research_team.content[0].image'))" alt="">
              <img :src="require('' + t('research_content.research_team.content[1].image'))" alt="">
            </div>
            <view-more-button 
              @viewMore="changeSelectedArticle" 
              :selectedArticle="'research_team'"
            />
          </div>
        </div>
        <div class="w-100 column">
          <div class="articles-box" :style="{height: '905px'}">
            <h2>
              {{t('research_content.result_and_impacts.title')}}
            </h2>
            <p 
              class="box-content" 
              v-html="t('research_content.result_and_impacts.content')"
              :style="{height: '597px'}"
            >
            </p>
            <view-more-button 
              @viewMore="changeSelectedArticle" 
              :selectedArticle="'result_and_impacts'"
            />
          </div>
          <div class="articles-box" :style="{height: '1218px'}">
            <h2>
              {{t('research_content.innovation_pathways.title')}}
            </h2>
            <p 
              class="box-content" 
              v-html="t('research_content.innovation_pathways.content')"
              :style="{height: '912px'}"
            >
            </p>
            <view-more-button 
              @viewMore="changeSelectedArticle" 
              :selectedArticle="'innovation_pathways'"
            />
          </div>
        </div>
      </div>
      <back-button @onClick="backToResult"/>
      <experience-again-button @onClick="scanAgain" :style="{marginTop: '50px'}"/>
    </div>
  </div>
  <full-article 
    v-if="selectedArticle !== ''"
    :class="viewMoreAnimation"
    :selectedArticle="selectedArticle"
    @close="changeSelectedArticle"
    @nextArticle="nextArticle"
    @prevArticle="prevArticle"
    @scanAgain="scanAgain"
  />
</template>

<script>
import {useI18n} from "vue-i18n";
import BackButton from "./components/back-button.vue";
import ExperienceAgainButton from './components/experience-again-button.vue';
import ViewMoreButton from './components/view-more-button.vue';
import FullArticle from './full-article.vue';
export default {
  setup() {
    const {t, tm} = useI18n();
    return {
      t,
      tm
    }
  },
  emits: [
    'updateShowResearchContent',
    'updateShowResult',
    'removeProfile',
  ],
  components: {
    BackButton,
    ExperienceAgainButton,
    ViewMoreButton,
    FullArticle
  },
  data() {
    return {
      selectedArticle: "",
      articles: [
        'global_challenge',
        'result_and_impacts',
        'awards',
        'innovation_pathways',
        'research_team'
      ],
      viewMoreAnimation: ''
    }
  },
  methods: {
    backToResult() {
      this.$emit('updateShowResearchContent', false)
      this.$emit('updateShowResult', true, 'research');
    },
    scanAgain() {
      this.$emit('updateShowResearchContent', false)
      this.$emit('updateShowResult', false, 'research');
      this.$emit('removeProfile');
    },
    changeSelectedArticle(selected) {
      if (selected == '') {
        this.viewMoreAnimation = 'close-article'
        setTimeout(() => {
          this.selectedArticle = selected;
        }, 250);
      } 
      else {
        this.selectedArticle = selected;
        this.viewMoreAnimation = 'open-article'
      }
    },
    nextArticle() {
      const currentIndex = this.articles.indexOf(this.selectedArticle);
      this.selectedArticle = this.articles[(currentIndex + 1) % this.articles.length];
    },
    prevArticle() {
      const currentIndex = this.articles.indexOf(this.selectedArticle);
      this.selectedArticle = this.articles[((currentIndex - 1) + this.articles.length) % this.articles.length];
    }
  },
}
</script>

<style scoped>
.articles {
  display: flex;
  width: 100%;
  gap: 30px;
  margin-bottom: 85px;
}
.articles-box {
  padding: 60px 86px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 80px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.box-content {
  overflow: hidden;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 54px;
  /* or 135% */
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);

  text-align: justify;

  background: linear-gradient(180deg, #000000 73.98%, rgba(0, 0, 0, 0) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.close-article {
  animation: close .5s ease 1 forwards;
}
@keyframes close {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
.column {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.content {
  position: absolute;
  top: 0%;
  padding: 240px 270px;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  animation: fade-in 1s ease 1 forwards;
}
@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
h2 {
  font-weight: 400;
  font-size: 50px;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);

  color: #000000;
}
.open-article {
  animation: opening .5s ease 1 forwards;
}
@keyframes opening {
  0% {opacity: 0;}
  100% {opacity: 1;}
}
.research-team-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.research-team-summary img {
  width: 300px;
}
.result-container {
  position: absolute;
  top: 496px;
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 2;
}
.title {
  font-weight: 400;
  font-size: 140px;
  text-align: center;
  color: #000000;
  margin-bottom: 50px;
  text-shadow: 0px 4px 4px rgba(250, 250, 250, 0.25);
}
ul {
  padding-left: 60px;
}
</style>