<template>
    <div class="start-content" id="start-content">
      <div class="description">
        <conditions-hud v-if="conditions" :conditions="conditions"/>
      </div>

      <div class="countdown" v-if="ready">
        <p>
            {{t('message.ready')}}
        </p>
        <p>
            {{countdown}}
        </p>
      </div>

      <div v-if="conditionGuidance !== ''" class="condition-guidance">
        <p class="condition-guidance-text">
            {{conditionGuidance}}
        </p>
      </div>
  </div>
</template>

<script>
import faceAnimation from "./assets/face-animation.json"
import ConditionsHud from "./conditions-hud.vue"
import {useI18n} from "vue-i18n";
export default {
  setup() {
    const {t} = useI18n();
    return {
      t
    }
  },
  props: {
    countdown: Number,
    conditions: Object,
  },
  components: {
    ConditionsHud,
  },
  computed: {
    ready() {
        if (this.conditions) {
            return Object.values(this.conditions).every((item) => item);
        }
        return false;
    },
    conditionGuidance() {
        if (!this.conditions) {
            return ''
        }
        var guide;
        const lighting = this.conditions.brightness;
        const distance = this.conditions.distance_far && this.conditions.distance_close;
        const centered = this.conditions.centered;
        const movement = this.conditions.movement;
        const serverReady = this.conditions.serverReady;
        if (!lighting) { // lighting condition not met
            guide = 'lighting'
        } else if (!distance) { // distance condition not 
            if (this.conditions.distance_far && !this.conditions.distance_close) {
                guide = 'distance_close';
            } else if (!this.conditions.distance_far && this.conditions.distance_close) {
                guide = 'distance_far'
            }
        } else if (!centered) { // centered condition not met
            guide = 'centered'
        } else if (!movement) { // movement condition not met
            guide = 'movement'
        } else if (!serverReady) { // serverReady condition not met
            guide = 'serverReady'
        } else if (lighting && distance && centered && movement && serverReady) {
            guide = "";
        } 
        if (guide !== '') {
            return this.t('condition_checking.guidance.' + guide);
        } else {
            return "";
        }
    },
  },
  data() {
    return {
        faceAnimation
    }
  },
}
</script>

<style scoped>
.description {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    letter-spacing: 0.25px;
    color: #333D47;
    text-align: justify;
    padding: auto 80px;

    width: 1163px;
    height: 234px;
    background: #FFFFFF;
    border-radius: 100px;
    display: flex;
    align-items: center;
}

.header {
    background-color:rgba(165, 145, 92, 0.9);
    border-radius: 24px 24px 0px 0px;
    padding: 120px 80px;
}

.message {
  margin-top: 30px;
  font-style: normal;
  text-align: center;
  color: white;
  font-size: 24px;
}
.start-content {
    position: absolute;
    top: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    animation: fade-in 1s ease 1 forwards;
}

@keyframes fade-in {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.countdown {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    font-weight: 400;
    font-size: 90px;
    text-align: center;

    color: #FFFFFF;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.subtitle {
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    color: white;
}
.title {
    font-weight: 600;
    font-size: 56px;
    text-align: center;
    color: white;
}
.vital-sign {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: 0.25px;
    color: white;
    padding-top: 44px;
}

.description .title {
    font-weight: 600;
    font-size: 24px;
    color: black;
    margin-bottom: 24px;
}

.description .content {
    font-weight: 300;
    font-size: 14px;
    line-height: 160%;
    color: #333D47;
    text-shadow: 0px 15px 22px rgba(0, 0, 0, 0.06), 0px 8px 28px rgba(0, 0, 0, 0.08);
    margin-bottom: 24px;
    padding-left: 20px;
}
.condition-guidance {
    position: relative;
    top: 472px;
    padding: 30px 50px;
    background: #FFFFFF;
    border-radius: 60px;
}
.condition-guidance-text {
    font-size: 54px;
    margin-bottom: 0;
    text-align: center;
}
</style>