const locales = {
  '中': {
    page: {
      dashboard: '面板',
      settings: '設置',
      help: '幫助',
      logout: '登出',
      supervisor_view: '管理員視圖',
      coming_soon: '敬請關注!<br>我們即將到來...',
    },
    guest_signin: '繼續以訪客身份登錄',
    logout_prompt: '你確定你要退出嗎？',
    instruction: {
      stay_still: '正在測量中，請保持靜止。',
      count_down1: '將在',
      count_down2: '秒後開始測量，請保持靜止。',
    },
    button: {
      mode: '顯示模式',
      fever_percentage: '臉上發燒皮膚比例',
      fever_threshold: '發燒檢查體溫',
      start: '开始扫描',
      stop: '停止',
      shutdown: '關機',
      cancel: '取消',
      ok: '確定',
      all: '所有',
      power_on: '開機',
      full_screen: '全屏',
      save_settings: '保存設置',
      upgrade: '升級',
      change_password: '更改密碼',
      next: "下一步",
      previous: "上一步",
      finish: "完成",
      continue: '繼續',
      show_health_report: '顯示健康報告',
      clear_user_information: '清除用戶資料',
      send_to_email: '發送到郵件',
      rate_us: ' 評價我們！',
      submit: '提交',
      on: "ON",
      off: "OFF",
      start_again: "重新開始",
      back_to_homepage: "回到主页",
      agree: "已閱讀及同意",
      disagree: "不同意",
      immediately: '立刻开始',
      back: "上一页",
      scan_complete: "扫描完成!",
      view_research_content: "查看研究內容",
      experience_again: "再次体验",
      view_more: "浏览更多"
    },
    labels: {
      sending: '正在發送...',
      user_profile: '用戶資料',
      rate_us: ' 您的反饋意見對我們很重要，請給我們評個分！',
      poor: '劣',
      good: '優',
      dissatisfied: '不滿意',
      satisfied: '滿意',
    },
    graph: {
      measurement: '測量',
      time: '時間',
      day: '日',
      week: '周',
      month: '月',
      trend_line: '顯示趨勢線',
      excellent: "優秀",
      good: "良好",
      poor: "欠佳",
    },
    mode: {
      normal: '正常',
      debug: '調校'
    },
    threshold: {
      auto: '自動'
    },
    message: {
      startup: '系統已關閉。請按「開始」。',
      loading: '加載中',
      calibrating: '保持靜止，智能系統調整中 ...',
      no_face_box: '找不到人臉 ...',
      stopping: '{system_short_name} 智能系統停止中...',
      confirm_shutdown: '確定要關閉主機嗎?',
      important_message: '重要訊息',
      emmisivity: '{short_name} v{version} 是專為 Emmisivity <span class="text-danger font-weight-bold">{emmisivity}</span> 的 FLIR A315 而設計的。',
      auto_sys_restart: '自動重新啟動',
      prevent_overheat: '避免系統過熱，重新啟動中...',
      camera_error: '發現相機錯誤',
      system_error: '發現系統錯誤',
      camera_fatal_error: '因相機問題，系統自動停止，請聯絡 {contact}。謝謝。',
      system_fatal_error: '因系統發生問題，系統自動停止，請聯絡 {contact}。謝謝。',
      system_off: '系統已關閉。',
      tap_to_view: '按一下觀看。',
      condition_check_failed: '(1) 請移動進人形框內 <br/>(2) 盡量保持靜止。',
      coming_soon: '即將推出',
      breath_in: '吸',
      breath_out: '呼',
      bad_scan_message: '請重新掃描',
      weak_signal_message: '信號微弱',
      settings_updated: '設定已更新',
      password_updated: '密碼已更新',
      password_update_failed: '更改密碼失敗，請檢查您的密碼是否正確',
      fill_profile: '請填寫您的用戶資料',
      subscribe: "訂閱以觀看內容!",
      scan_quality: "掃描質量",
      email: '電郵發送至',
      feedback: "已收到您的意見，非常感謝！祝您有個美好的一天~",
      ready: "准备！",
      scan_30s: "请站稳 30秒以完成扫描",
      see_result: "点击查看结果"
    },
    condition_checking: {
      conditions: {
        lighting: "光線",
        distance: "距离",
        centered: "正中",
        movement: "移动",
        server_ready: "服务器就绪"
      },
      guidance: {
        lighting: "请改善您所在环境的光度",
        distance_far: "请往前走一步",
        distance_close: "请往后走一步",
        centered: "请站在中心",
        movement: "请站稳",
        serverReady: "重连中"
      }
    },
    disclaimer: {
      disclaimer1: "免責聲明：此軟件不儲存任何個人資料。",
    },
    copyright: "\u00A9 2021-2023 全境智能有限公司 版權所有",
    last_scan: '最後一次掃描',
    last_update: '最後更新',
    vs_descriptions: {
      bloodPressure: "成人的血壓小於120/80 mmHg。",
      bmi: "成人的身體質量指數在18.5與24.9之間。",
      respiratoryRate: "成人的呼吸率一般在每分鐘6至24次。",
      generalRisk: "得到心血管疾病的概率，越小越好。",
      covidRisk: "得到新冠肺炎的概率，越小越好。",
      heartRate: "成人的靜息心率在每分鐘60至100下。",
      hrvSdnn: "成人的心率變化大約是20ms或以上。",
      //      hrvSdnn: "成人一天内的心率變化大約是68-219ms之間。",
      hrvIbi: "成人的靜息IBI在600至1000 ms之間。",
      spo2: "成人的血氧量大約爲95-100%。",
      stress: "正常的壓力指數應低於2。",
      temperature: "成人的正常體溫大約是37攝氏度。",
      facialSkinAge: " 您一直都年輕美麗/帥氣!",
    },
    option: {
      yes: '有',
      no: '沒有',
      logout_yes: '是',
      logout_no: '不是',
    },
    user: {
      supervisor: '管理員',
      guest: '訪客',
    },
    company_info: {
      company_information: "公司用戶信息",
      company_id: "公司用戶編號",
      project_id: "項目編號",
      site_id: "站點編號",
      location: "站點位置",
      subscription_plan: '訂閲計劃',
      valid_until: '有效期至',
    },
    user_info: {
      user_id: '用戶編號',
      profile_image: '頭像',
      personal_information: '個人信息',
      subscription_plan: '訂閲計劃',
      valid_until: '有效期至',
      name: '名稱',
      nickname: '綽號',
      birthday: '生日',
      height: '身高（厘米）',
      weight: '體重（公斤）',
      gender: '出生性別',
      male: '男',
      female: '女',
      unit_system: '單位系統',
      country: '國家',
      countryOptions: {
        hongkong: "香港，中國",
        china: "中國大陸",
      },
      metric: '公制',
      imperial: '英制',
      medical_history: '病史',
      smoking: '您有吸煙嗎？',
      hypertension: '您有高血壓嗎？',
      bp_medication: '您有在服用降血壓藥物嗎？',
      diabetic: '您是糖尿病患者嗎？',
      heart_disease: '您有心臟疾病嗎？',
      depression: '您有抑鬱症嗎?',
      department: '部門',
      type_1: '類型1',
      type_2: '類型2',
      plan_type: {
        free: '免費',
        pro: "專業",
        enterprise: '企業',
        team: '團隊',
        unlimited: '無限制'
      }
    },
    password: {
      password: '密碼',
      old: '舊密碼',
      new: '新密碼',
      confirm: '確認密碼',
      not_match: '密碼不相符'
    },
    detailed_info: {
      history: '歷史數據',
      trend_analysis: '趨勢分析',
      note: '注釋',
      references: '參考資料',
      content: {
        anomalies: "在 {date}，您的 {vital_sign} 記錄有異常情況",
      },
    },
    no_data: '沒有數據',
    help_content: {
      disclaimer: {
        title: "免責聲明",
        p1: "本系統是以攝像頭為基礎的非接觸式健康監測方案，可測量生命體徵如心率、呼吸頻率、血壓和血氧飽和度等。 捕捉影像只會作計算之用，並不會存儲任何影片片段。",
        p2: "WVS適合不需要持續或密集健康監測的個人用戶，且不能用作測量生命體徵的唯一方式或取代與醫學專家的諮詢。系統的生命體徵數據不適用於醫療目的， 如果您有任何進一步的查詢，請聯繫專業的醫療人員。"
      },
      setup: {
        title: "設置指南",
        dos: {
          title: "掃描進行時，請...",
          p1: "爲了更好的結果和使用體驗，請按以下指示完成掃描：",
          1: "將攝像頭放置於視線水平附近",
          2: "不要搖晃設備",
          3: "在有充足光源的地方進行掃描，確保光線（最好室内光源）平均照到臉上",
          4: "請避免背光",
          5: "請保持臉部在綠框中間及面向鏡頭",
          6: "請確保和鏡頭之間有合適的距離"
        },
        donts: {
          title: "掃描進行時，請不要...",
          1: "不要帶口罩",
          2: "不要帶帽子",
          3: "不要被任何東西遮擋臉部",
          4: "臉上不要有不均勻的光線 （例如陽光）",
          5: "不要談話",
          6: "身體不要大幅度移動",
          7: "不要把頭轉向旁邊"
        },
        low_accuracy: {
          title: "低準確率例子",
          1: "環境太暗或太光",
          2: "光線不均或背光",
          3: "佩戴口罩或帽子或被東西遮擋臉部",
          4: "沒有面向鏡頭",
          5: "與鏡頭的距離太遠",
          6: "與鏡頭的距離太近",
        },
      },
      operation: {
        title: "操作建議",
        ground_truth: {
          title: "請先使用接觸式設備測量數據",
          p1: "在激活 WVS 系統之前，用戶應先用接觸式脈搏血氧儀作量度，再將 WVS 系統的結果與接觸式脈搏血氧儀的結果進行比較，以得知結果的可參考性。",
          p2: "請按照以下步驟以打開和操作接觸式脈搏血氧儀：",
          1: "擠壓設備背面將其打開。",
          2: "將食指或無名指插入設備，顯示屏朝上。 慣用右手的使用者，請使用右手食指或無名指。 慣用左手的使用者，請使用左手食指或無名指。",
          3: "指尖應觸碰到設備內部的手指擋塊。 如果手指沒有正確定位，請從步驟 1 再次開始。",
          4: "該設備的顯示屏上將顯示您的生命體徵",
          p3: `有關如何使用接觸式設備的更詳細說明，請參閱<a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">此處</a>的設備手冊。`
        },
        scanning: {
          title: "健康掃描步驟",
          p1: "使用 WVS 掃描系統的步驟如下：",
          1: "掃描開始前，先選擇光源充足的地方舒適地就座，確保光線（最好是室内光源）平均地照到臉上。然後將電腦放置在穩定的平面上，避免設備搖晃。",
          2: "點擊 WVS 系統左側導覽列上的主頁按鈕（第一個圖標），再點擊右上角的綠色「開機」按鈕以啟動相機和面部掃描儀系統。 該頁面應顯示「正在啟動... 」，如下圖所示。如提示允許 WVS 使用您的相機，請點擊允許。",
          caption_2: "生命體徵掃描儀啟動中",
          3: "系統準備就緒後將啟動攝像頭並顯示鏡頭畫面，同時顯示一個綠色框代表檢測到的面部範圍。請確保身體和鏡頭之間有合適的距離，保持臉部在綠框中間及面向鏡頭。",
          caption_3: "系統已啟動",
          4: "點擊綠色圓圈「開始」按鈕以開始掃描程序。 在開始掃描之前，您需要遵循掃描儀中顯示的條件。 滿足條件後，屏幕會出現三秒倒計時，表示掃描即將開始。",
          caption_4: "掃描啟動條件",
          5: "掃描進行時請跟隨綠色框下方綠色圓圈中出現的呼吸指示調整呼吸。 呼吸指示將在「吸氣」和「呼氣」之間切換，如下圖所示，您可從掃描進度圓圈中看到剩餘所需的時間。",
          note_5: "注意：如呼吸速度與指示不吻合，掃描不會檢測到錯誤，但仍建議跟隨呼吸指示以正常靜息速度呼吸。",
          caption_5: "掃描開始",
          6: "掃描會在30秒內完成，並在頁面上顯示生命體徵結果。請等待掃描完成以查看結果。",
          caption_6: "基本生命體徵",
          7: "若您想瀏覽詳細的掃描報告，請點擊導覽列上的第二個圖示前往數據板。",
          caption_7: "掃描結果概覽",
          laptop: "使用手提電腦",
          phone: "使用電話",
          pc: "使用桌上型電腦"
        }
      }
    },
    scan_tutorial: {
      title: "Vitals™ 掃描快速指南",
      dos: "請......",
      donts: "請不要......",
      step1: {
        title: "保持身體處於綠色邊框內",
        paragraph: "點擊開始按鈕後，屏幕上將出現綠色人形邊框。 邊框將指引您到正確的位置和距離使掃描更有效。 請確保您處於綠色邊框內。",
        do1: "將相機放置在視線水平附近",
        do2: "將臉部保持在邊框內並面向相機",
        do3: "確保您和相機之間距離適當",
        dont1: "不要坐在離設備太遠或太近的位置",
      },
      step2: {
        title: "確保相機平穩",
        paragraph: "相機搖晃可能會影響掃描的閱讀。請確保設備被放在平穩的位置並儘量減少移動。",
        do1: "將設備放在平穩的表面上",
        do2: "避免晃動設備",
        dont1: "不要談話",
        dont2: "身體不要大幅度移動 "
      },
      step3: {
        title: "在相機上顯示整張臉",
        paragraph: "確保您的臉完整顯示在相機上。 拿走任何會遮擋臉部的東西。",
        do1: "面向相機",
        do2: "確保您的臉沒有被帽子、連帽衫、口罩、頭髮等遮住。",
        dont1: "不要戴任何會遮住臉的東西（帽子、連帽衫、口罩）",
        dont2: "不要側過臉"
      },
      step4: {
        title: "E確保臉上的光線均勻充足",
        paragraph: "不均勻的光線可能會影響閱讀的準確性。 建議使用室內照明以更容易確保照明正確。",
        do1: "選擇光線充足的區域，確保光線（最好是室內光線）均勻地照射在臉上",
        dont1: "臉上不要有不均勻的光線（例如：陽光）",
        dont2: "背光"
      }
    },  
    user_input: {
      question: "请输入您的",
      gender: "出生性别",
      age: "年齡",
      select_gender: "请输入您的出生性別",
      select_age: "请输入您的年龄",
    },
    start_page:{
      title: '非接触式<br>生命体征<br>檢查',
      touch: "点击开始",
      click_me: "点我！"
    },
    scan_result: {
      title: "专属您的健康分析",
      skin_age: "肤质",
      wellness: "健康指数",
      stress: "压力水平",
      skip: "查看適合您的養生茶及飲食建議 >",
      email: "請輸入您的電郵，以獲取完整版的健康分析報告，<br>及LIFY Wellness的特選優惠：",
      email_placeholder: "輸入電郵",
      back: "返回健康分析",
      lify_recommendation: "適合您的養生茶",
      qr: "立即掃描二維碼<br>查看完整版的健康分析報告"
    },
    research_content: {
      title: "研究内容",
      global_challenge: {
        title: "应对一个全球性挑战",
        content: "新冠疫情是一场世界性灾难。自疫情爆发以来，许多社区都在尽力识别潜在的发热患者以阻止病毒传播。与此同时，关注健康问题的人越来越多，接触式健康监测设备 （如可穿戴设备） 也越来越受欢迎。\
        <br><br>\
        尽管如此，苏孝宇教授团队选择了一种基于摄像头的保健技术。和这种技术相比，可穿戴设备的成本更高，而且需要定期升级软件和充电，效率也就更低。更不用说新冠病毒传染性强，本就应该尽量避免身体接触。因此，基于摄像头的保健技术被视为优于可穿戴设备，能够进一步提高用户的生活质量。"
      },
      awards: {
        title: "获奖情况",
        content: 
        [
          '“冠军 (保持社交联系)” 城市创科大挑战大专院校组',
          '“得奖初创公司”香港貿發局創業快线',
          '“特等奖及一等奖”第 7 屆香港大學生創新及創業大賽',
          '“银奖”第 7 屆中國國際「互聯網+」大學生創新創業大賽'
        ]
      },
      research_team: {
        title: "团队成员",
        content: [
          {
            image: "./assets/professor-richard-so.png",
            name: "苏孝宇教授",
            title: "工程学院副院长",
            dept: "香港科技大学工业工程及决策分析学系，化学及生物工程学系"
          },
          {
            image: "./assets/kyle-wong.png",
            name: "黃君朗",
            title: "研究员",
            dept: "香港科技大学化学及生物工程学系"
          },
          {
            image: "./assets/nick-chin.png",
            name: "陈经纬",
            title: "研究员",
            dept: "香港科技大学工业工程及决策分析学系"
          },
          {
            image: "./assets/teric-chan.png",
            name: "陈子泰",
            title: "研究员 ",
            dept: "香港科技大学工业工程及决策分析学系"
          },
          {
            image: "./assets/kristian-suhartono.png",
            name: "Kristian SUHARTONO",
            title: "研究员",
            dept: "香港科技大学工业工程及决策分析学系"
          },
        ]
      },
      result_and_impacts: {
        title: "成果和影响",
        content: "自2020年1月以来，体温筛查系统已对约300万人进行发热筛查。该系统经调整后已广泛应用于香港各地，包括机场、渡轮码头、边境管制站、政府设施 （如，中区政府总部建筑群、香港新一代文化协会、税务大楼、立法会)、法院、学校、大学、长者中心及博物馆。\
        <br><br>\
        此外，该项目衍生了一家公司——全境智能有限公司，该公司的愿景是塑造数字健康的未来。该研究团队将继续开发基于摄像头的健康监测技术，相信这种新技术将颠覆医疗保健市场。"
      },
      innovation_pathways: {
        title: "创新举措",
        content: "体温筛查：<br>\
        2020年年初新冠疫情爆发，本研究项目随之启动，开发了一个基于摄像头的体温筛查系统，可以识别潜在的发烧患者。该系统是一个双谱摄像头，可以轻松地安装在墙壁、天花板、便携式手推车或三脚架上。该摄像头配备了人工智能软件，能够检测人脸温度。\
        <br><br>\
        用户可以通过显示屏在检测范围内监控人员的脸部温度。体温正常人员被标以绿框，疑似发烧人员则被标以红框，该系统还配有可自行开关的警报声音。\
        <br><br>\
        得益于人工智能驱动的算法，该系统具有强大的定位和分析功能，能够以非侵入方式准确地检测人脸温度。即使人脸被口罩、太阳镜或帽子部分遮挡，该系统的测温功能也不受影响。\
        <br><br>\
        此外，该系统在进行设置后，可排除因距离和操作环境而引起测温不准的问题，因此即使在传统技术无法正确测温的场所，该系统也能胜任。该系统收集到的数据还可以保存在跨平台数据库中，日后用于跟踪接触者和分析。\
        <br><br>\
        不仅是体温筛查：<br>\
        2020年9月，在成功研发体温检测技术的基础上，该研究团队将该系统的功能扩展到检测其他生理信号。改进后的检测系统还可以测量其他核心生命体征，包括心率及其变化、呼吸频率、血氧饱和度、压力指标。用户通过自己的智能手机、平板电脑或电脑，就能在短短30秒内获得关于自身生理状况的、具有指导意义的信息。\
        <br><br>\
        该系统的非凡之处在于其主要利用摄像头工作，无需接触人体。虽然生命体征的变化无法为肉眼所见，却可通过皮肤上细微的光线变化观察到。该系统将深度学习应用于远程容积描记法（这种技术用于测量由血液流动变化引起的身体不同部位的体积变化），能够有效识别皮肤上细微的光线变化。\
        <br><br>\
        传统上，获取生命体征等健康信息需要使用接触式传感器或设备。然而，该团队已成功提取这些健康信息，它们对于诊断发烧、压力、心脏和呼吸系统风险等健康问题非常重要。如此一来，人们可以比以往任何时候更便捷地获得健康分析服务。"
      }
    },
    scanning_result: {
      title: "扫描结果",
      vital_signs: {
        heart_rate: {
          title: "心率",
          unit: "bpm"
        },
        facial_skin_age: {
          title: "面部皮肤年龄",
          unit: ""
        },
        spo2: {
          title: "氧气水平",
          unit: "%"
        },
        blood_pressure: {
          title: "血压(Beta)",
          unit: "mmHg",
        },
        stress: {
          title: "压力水平",
          unit: "out of 5"
        },
        respiratory_rate: { 
          title: "呼吸率",
          unit: "bpm"
        }
      },
      condition_text: {
        excellent: "你做得非常好！繼續保持！",
        fair: "你整體表現不錯！",
        needs_improvement: "你可能需要更關注自己的身體健康。",
      }
    }
  },
  "EN": {
    page: {
      dashboard: 'Dashboard',
      settings: 'Settings',
      help: 'Help',
      logout: 'Logout',
      coming_soon: 'Stay tuned!<br>We are Coming Soon...',
    },
    guest_signin: 'Continue as Guest',
    logout_prompt: 'Are you sure you want to log out?',
    instruction: {
      stay_still: 'Scanning in progress, stay still.',
      count_down1: 'Scanning starting in ',
      count_down2: ', stay still'
    },
    button: {
      mode: 'Mode',
      fever_percentage: 'Fever Percentage',
      fever_threshold: 'Threshold Temp.',
      start: 'Start',
      stop: 'STOP',
      shutdown: 'SHUT DOWN',
      cancel: 'Cancel',
      ok: 'OK',
      all: 'ALL',
      power_on: 'POWER ON',
      full_screen: 'FULL SCREEN',
      save_settings: 'Save Settings',
      upgrade: 'Upgrade',
      change_password: 'Change Password',
      next: "Next",
      previous: "Previous",
      continue: 'CONTINUE',
      show_health_report: 'Show Health Report',
      clear_user_information: 'Clear User Info',
      send_to_email: 'Send to Email',
      rate_us: 'Rate Us!',
      submit: 'Submit',
      on: "ON",
      off: "OFF",      
      start_again: "Start Over",
      back_to_homepage: "Back",
      back: "Back",
      scan_complete: "Scanning Complete!",
      view_research_content: "View Research Content",
      experience_again: "Experience Again",
      view_more: "View More"
    },
    labels: {
      sending: 'sending...',
      user_profile: 'User Profile',
      rate_us: 'Your feedback is valuable to us, please give us a rating!',
      poor: 'Poor',
      good: 'Good',
      dissatisfied: 'Dissatisfied',
      satisfied: 'Satisfied',
    },
    graph: {
      measurement: 'Measurement',
      time: 'Time',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      trend_line: 'Show Trend Line',
      excellent: 'Excellent',
      good: 'Good',
      poor: 'Poor'
    },
    mode: {
      normal: 'Normal',
      debug: 'Debug'
    },
    threshold: {
      auto: 'Auto'
    },
    message: {
      startup: 'System is OFF, press "POWER ON" to start.',
      loading: 'Loading',
      calibrating: 'Stay still, Calibrating ...',
      no_face_box: 'No Face Box ...',
      stopping: 'Stopping {system_short_name}...',
      confirm_shutdown: 'Are you sure you want to shutdown the system?',
      important_message: 'Important Message',
      emmisivity: '{short_name} v{version} is designed to work with FLIR A315 with emmisivity set to <span class="text-danger font-weight-bold">{emmisivity}</span>.',
      auto_sys_restart: 'Automatic System Restart',
      prevent_overheat: 'Restarting the system to prevent over-heating...',
      camera_error: 'Camera Error Detected',
      system_error: 'System Error Detected',
      camera_fatal_error: 'System automatically stopped due to camera error, please contact {contact}. Thank you.',
      system_fatal_error: 'System automatically stopped due to error, please contact {contact}. Thank you.',
      system_off: 'System is OFF.',
      tap_to_view: 'Tap to View',
      condition_check_failed: '(1) Please move into the pur ple human <br/>(2) stay still',
      coming_soon: 'Coming Soon',
      breath_in: 'In',
      breath_out: 'Out',
      bad_scan_message: 'Bad scan, please restart the scan',
      weak_signal_message: 'Weak signal',
      settings_updated: 'Settings updated',
      password_updated: 'Password updated',
      password_update_failed: 'Failed to change password, please check your password',
      fill_profile: 'Please fill in your company profile',

      subscribe: "Subscribe to see this content!",
      scan_quality: "Scan Quality",
      email: 'Email sent to',
      feedback: "Feedback received, Thank You!! Have a nice day~",
      final_check: "Look straight at the camera.<br>The scan will begin in {second} seconds.",
      ready: "Ready!",
      scan_30s: "Please stay still for 30 seconds to complete the scan",
      see_result: "Tap to see your result!",
      view_research_content: "View Research"
    },
    condition_checking: {
      conditions: {
        lighting: "Lighting",
        distance: "Distance",
        centered: "Centered",
        movement: "Movement",
        server_ready: "System Ready"
      },
      guidance: {
        lighting: "Please improve the lighting of your environment",
        distance_far: "Please move forward",
        distance_close: "Please move backward",
        centered: "Please move to the center of the hologram",
        movement: "Please stay still",
        serverReady: "Connecting to the server..."
      }
    },
    disclaimer: {
      disclaimer1: "Disclaimer: The software does not store any personal data.",
    },
    copyright: "\u00A9 2021-2023 PanopticAI Ltd. All Rights Reserved.",
    last_scan: 'Last Scan',
    last_update: 'Last Updated',
    option: {
      yes: 'Yes',
      no: 'No',
      logout_yes: 'Yes',
      logout_no: 'No',
    },
    user: {
      supervisor: 'Supervisor',
      guest: 'Guest',
    },
    company_info: {
      company_information: "Company Information",
      company_id: "Company Id",
      project_id: "Project Id",
      site_id: "Site Id",
      location: "Site Location",
      subscription_plan: 'Subscription Plan',
      valid_until: 'Valid Until',
    },
    user_info: {
      user_id: 'User Id',
      profile_image: 'Profile Image',
      personal_information: 'Personal Information',
      subscription_plan: 'Subscription Plan',
      valid_until: 'Valid Until',
      name: 'Name',
      nickname: 'Nickname',
      birthday: 'Birthday',
      height: 'Height (cm)',
      weight: 'Weight (kg)',
      gender: 'Gender at birth',
      male: 'Male',
      female: 'Female',
      unit_system: 'Unit System',
      country: 'Countries',
      countryOptions: {
        hongkong: "Hong Kong, China",
        china: "Mainland China",
      },
      metric: 'Metric',
      imperial: 'Imperial',
      medical_history: 'Medical History',
      smoking: 'Do you smoke?',
      hypertension: 'Do you have hypertension?',
      bp_medication: 'Are you taking blood pressure medication?',
      diabetic: 'Are you diabetic?',
      heart_disease: 'Do you have heart disease?',
      depression: 'Do you have depression?',
      department: 'Department',
      type_1: 'Type 1',
      type_2: 'Type 2',
      plan_type: {
        free: 'Free',
        pro: "Pro",
        enterprise: 'Enterprise',
        team: 'Team',
        unlimited: 'Unlimited'
      }
    },
    password: {
      password: 'Password',
      old: 'Old Password',
      new: 'New Password',
      confirm: 'Confirm Password',
      not_match: 'Password does not match'
    },
    detailed_info: {
      history: 'History',
      trend_analysis: 'Trend Analysis',
      note: 'Note',
      references: 'References',
    },
    no_data: 'No Data',
    help_content: {
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement \
        of health vital signs such as heart rate, breathing rate, blood pressure, and O2 saturation. Video \
        footage is captured through a device's camera, but no video footage is stored.",
        p2: "WVS is to be used by individuals who do not require constant or intensive health monitoring. The device \
        is not to be used as the only method for health vital signs measurement of the individuals and is not intended \
        to replace consultations with medical experts. The measurements are not served for medical purposes. Should you \
        have any further health enquiries, please contact health care professionals or other medical experts immediately."
      },
      setup: {
        title: "Set-up Guidelines",
        dos: {
          title: "Dos",
          p1: "For the best results, please do the following for the scanning:",
          1: "Put the camera near your eye level",
          2: "Prevent shaking the device ",
          3: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
          4: "Avoid backlighting ",
          5: "Keep your face well within the box and facing the camera",
          6: "Ensure suitable distance between you and the camera"
        },
        donts: {
          title: "Dont's",
          1: "Do not wear a mask",
          2: "Do not wear a hat",
          3: "Do not wear anything that will cover your face",
          4: "Do not have uneven light on you face (e.g. sunlight)",
          5: "Do not talk",
          6: "Do not make big movements",
          7: "Do not turn your head aside",
        },
        low_accuracy: {
          title: "Low Accuracy Examples",
          1: "The area is too bright or too dark",
          2: "Uneven lighting or backlighting",
          3: "The user is wearing a hat or having something cover one’s face",
          4: "The user is not facing the camera",
          5: "The distance between the user and the camera is too far",
          6: "The distance between the user and the camera is too close"
        }
      },
      operation: {
        title: "Operation",
        ground_truth: {
          title: "Measure Ground Truth with Contact-Based Device",
          p1: "The following steps should be taken before activating the WVS system so the user can compare the results from the WVS system with the results from the contact-based pulse oximeter.",
          p2: "The contact-based pulse oximeter is off until a finger is placed in it. To turn on and operate device, follow the following steps:",
          caption: `Contact-Based Device Scanning`,
          1: "Squeeze the back of the device to open it.",
          2: "Insert the index finger or ring finger into the device with the display facing up. For a left-handed person, use the left-hand index finger or ring finger. For a right-handed person, use the right-hand index finger or ring finger.",
          3: "The tip of the finger should reach the finger stop inside of the device. If the finger is not correctly positioned, repeat from Step 1.",
          4: "The device will now show your vital signs on the display.",
          p3: `For more detailed instructions on how to use the contact-based device, please refer to the manual for the device <a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">here</a>.<br>`
        },
        scanning: {
          title: "Using The Health Scanning System",
          p1: "The following steps are done to use the WVS scanning system:",
          1: "Sit comfortably in a well-lit area before scanning. Ensure the light (preferably indoor light) is evenly shed on your face. Then place the computer on a sturdy surface to prevent shaking.",
          2: `Click on the home button (the first icon) on the Navigation Bar on the left side of the WVS system. Then click on the green '<i>Power On</i>' 
          on the top right corner to start up the camera and face scanner system. The page will then display '<i>Starting up …</i>' as shown in Figure 2 below. Click allow, if prompted to enable WVS to use your camera.`,
          caption_2: "Starting up the vital sign scanner",
          3: "When the system is ready, the system will activate your device’s camera and show it in the <i>Homepage</i> along with a green box indicating your detected face. Keep your face in the center of the green frame and facing the lens while ensuring that there is an appropriate distance between your body and the camera.",
          caption_3: "Powered up system",
          4: `Click on the green circle "<i>Start</i>" button to begin the scanning process. Before beginning the scan, you will need to follow pass the <i>Scanning 
          conditions</i> shown in the face scanner and stay still while the scanner is calibrating. Once the scanning conditions are met, a three second 
          countdown will appear in the middle of the screen to show that the scan is starting.`,
          caption_4: "Conditions for scanning",
          5: `Follow the <i>Breathing guide</i> instructions that appears in the green circle throughout the scanning process. The breathing guide will change 
          between '<i>In</i>' and '<i>Out</i>' to indicate breathing in and breathing out, as shown in Figure 12 and Figure 13. The <i>Scanning progress</i> circle 
          can be seen in the scanner too. The <i>Scanning progress</i> circle indicates the remaining time till the scanning procedure is completed. `,
          caption_5: "Scanning started",
          note_5: `Note: Users are reminded to follow the in and out guide to breath at normal resting speed, although no error in the scan will be detected if the 
          breathing in and out are not matched precisely with the visual indicator.`,
          6: `The scanning progress will complete within 30 seconds, and the vital sign results will be displayed while scanning is 
          being completed. Please wait until the scan is complete to see the results. `,
          caption_6: "Basic vital signs",
          7: `To see the detailed scanning result, go to the "<i>Dashboard</i>" by clicking on the <i>Navigation bar</i>.`,
          caption_7: "Scanning results overview",
          laptop: "Using Laptop",
          phone: "Using Phone",
          pc: "Usign PC"
        }
      }

    },
    scan_tutorial: {
      title: "Quick Guide for Scanning on Vitals",
      dos: "Dos:",
      donts: "Don'ts:",
      step1: {
        title: "Position Yourself Inside The Green Border",
        paragraph: "Once you click on the start button, a green human border will appear on your screen. This will guide you for a correct position and distance to make the scanning more effective. Make sure you are positioning yourself to be inside the green border.",
        do1: "Put the camera near your eye level",
        do2: "Keep your face well within the border and facing the camera",
        do3: "Ensure suitable distance between you and the camera",
        dont1: "Do not sit too far away from or too close to the device",
      },
      step2: {
        title: "Ensure Camera is Steady",
        paragraph: "Shaky camera may affect the reading of the scanning. Please make sure that the device is placed on a steady place and do minimum movement.",
        do1: "Place device on steady surface",
        do2: "Prevent shaking the device",
        dont1: "Do not talk",
        dont2: "Do not make big movements "
      },
      step3: {
        title: "Whole Face Displayed on Camera",
        paragraph: "Make sure that your face is fully displayed on camera. Remove anything covering your face.",
        do1: "Face the camera",
        do2: "Make sure your face is not covered such as with hat, hoodie, mask, hair.",
        dont1: "Do not wear anything that cover your face (hat, hoodie, mask)",
        dont2: "Do not turn your face sideways"
      },
      step4: {
        title: "Ensure Even and Sufficient Lighthing on Your Face",
        paragraph: "Uneven lighting may affect the correctness of your reading. Indoor lighting is recommended to ensure correct lighting more easily",
        do1: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
        dont1: "Do not have uneven light on you face (e.g. sunlight)",
        dont2: "Backlighting"
      }
    },
    user_input: {
      question: "Please enter your information",
      gender: "Gender",
      age: "Age",
      select_gender: "Please select your gender",
      select_age: "Please select your age group",
    },
    start_page:{
      title: 'Contactless<br>Vital Signs<br>Screening',
      touch: "Touch to start",
      click_me: "Click Me!"
    },
    research_content: {
      title: "Research Content",
      global_challenge: {
        title: "Addressing Global Challenge",
        content: "COVID-19 is a worldwide scourge. Since the pandemic began, governments and communities have been identifying potentially infected individuals screening for symptoms such as fever. In the meantime, more and more people have become health-conscious. Consequently, contact-based health monitoring devices like wearables have risen in popularity.<br><br>\
        \
        Nevertheless, Professor Richard Hau Yue So and his team have opted for a camera-based solution for health and wellness. Unlike camera-based solutions, wearable devices are less affordable in terms of costs, and less efficient considering the need for regular software upgrade and battery recharge. Not to mention the high contagiousness of COVID-19 has discouraged physical contacts. Thus, camera-based devices are considered a better alternative to improve users’ quality of life."
      },
      awards: {
        title: "Awards",
        content: [
          '“Champion (Social Connectivity)” of the City I&T Grand Challenge in the University / Tertiary Category',
          '“Winning Startup” of the HKTDC Startup Express 2021',
          '“Grand Prize” and “1st Prize” of the 7th HK University Student Innovation and Entrepreneurship Competition',
          '“Silver Award” of The 7th China International College Students’ Internet+ Innovation and Entrepreneurship Competition'
        ]
      },
      research_team: {
        title: "Research Team",
        content: [
          {
            image: "./assets/professor-richard-so.png",
            name: "Prof. Richard SO",
            title: "Associate Dean of School of Engineering",
            dept: "Department of Industrial Engineering and Decision Analytics, Department of Chemical and Biological Engineering, HKUST"
          },
          {
            image: "./assets/kyle-wong.png",
            name: "Kyle WONG",
            title: "Researcher",
            dept: "Department of Chemical and Biological Engineering, HKUST"
          },
          {
            image: "./assets/nick-chin.png",
            name: "Nick CHIN",
            title: "Researcher",
            dept: "Department of Industrial Engineering and Decision Analytics, HKUST"
          },
          {
            image: "./assets/teric-chan.png",
            name: "Teric CHAN",
            title: "Researcher",
            dept: "Department of Industrial Engineering and Decision Analytics, HKUST"
          },
          {
            image: "./assets/kristian-suhartono.png",
            name: "Kristian SUHARTONO",
            title: "Researcher",
            dept: "Department of Industrial Engineering and Decision Analytics, HKUST"
          },
        ]
      },
      result_and_impacts: {
        title: "Result and Impacts",
        content: "Since January 2020, the body temperature screening system has been used for fever screening of approximately three million people. The system has been adapted and deployed widely across Hong Kong, including the airport, ferry terminals, border control points, government facilities (e.g.,Central Government Complex, Revenue Tower, Legislative Council), courts, schools, universities, elderly centres, and museums.<br><br>\
        \
        Moreover, Panoptic AI, a spinoff startup of the project is established with the vision to change the way our health is measured, interpreted and managed. The team will continue their work in developing camera-based health and wellness monitoring solutions to share the future of digital health. This next-generation technology is believed to disrupt the healthcare and wellness monitoring market."
      },
      innovation_pathways: {
        title: "Innovation Pathways",
        content: "Temperature screening:<br>\
        As COVID-19 struck in early 2020, the project kicked off with the development of a camera-based body temperature screening system which identifies potential fever patients. The body temperature screening system includes a bi-spectrum camera that can be easily installed on walls, ceilings, portable trolleys, or tripods. The camera, equipped with artificial intelligence (AI) software, is capable of detecting the facial temperature of people.\
        <br><br>\
        Users can monitor the facial temperature of people who are in the detection range through an output screen. Those with normal temperature are outlined with a green box, whereas suspected fever patients are outlined with a red box alongside an optional audio alarm.\
        <br><br>\
        The AI-powered algorithms have enabled the system to have strong targeting and analytical performance. Hence, it can accurately detect the facial temperature of people in a non-invasive way, even when their faces are partly covered by face masks, sunglasses, or hats.\
        <br><br>\
        The system can also be configured to compensate for the temperature influences caused by distance and the operating environment, widening the use of system at places that were previously not possible. Data can also be saved in a cross-platform database and used later for contact tracing and analytical purposes.\
        <br><br>\
        Beyond temperature:<br>\
        In September 2020, building on the success of body temperature detection system, the team extended the technology to measure other physiological signals. By combining AI computer-vision algorithms with signal processing techniques, an off-the-shelf camera can measure an individual’s vital signs, including heart rate and its variability, respiratory rate, blood oxygenation saturation, as well as stress index. In just 30 seconds, users can get actionable insights about their physiology using their own smartphones, tablets or computers.\
        <br><br>\
        What makes it remarkable is the use of camera-based, contactless methods. Though changes in vital signs are invisible to the naked eye, they can be observed through the subtle light changes on the skin. By applying deep learning to remote photoplethysmography, techniques used to measure changes in volume in different areas of the body due to changes in blood flow, the system can effectively identify these subtle light changes.\
        <br><br>\
        Traditionally, obtaining health information like vital signs require the use of contact sensors or devices. Yet, the research team has managed to extract this information which are important for diagnoses of health problems like fever, stress, cardiac and respiratory risks. The health analysis service has become more accessible than ever before."
      }
    },
    scanning_result: {
      title: "Scanning Result",
      vital_signs: {
        heart_rate: {
          title: "Heart Rate",
          unit: "bpm"
        },
        facial_skin_age: {
          title: "Facial Skin Age",
          unit: ""
        },
        spo2: {
          title: "Oxygen Level",
          unit: "%"
        },
        blood_pressure: {
          title: "Blood Pressure (Beta)",
          unit: "mmHg",
        },
        stress: {
          title: "Stress Level",
          unit: "out of 5"
        },
        respiratory_rate: { 
          title: "Respiratory Rate",
          unit: "bpm"
        }
      },
      condition_text: {
        excellent: "You're doing great! Keep it up!",
        fair: "You are generally doing well!",
        needs_improvement: "You may need to pay more attention to your well-being.",
      }
    }
  },
};

module.exports = locales;
