<template>
  <div class="sidenav">
    <div id="side-bar">
      <router-link :to="'/home'">
        <img id="company-logo" src="./assets/icon.png">
      </router-link>
      <home-button id='home-button' class='icon'/>
      <!-- <chart-button id='chart-button' class='icon'/> -->
      <!-- <multi-user-button id='multi-user-button' class='icon'/> -->
      <setting-button id='setting-button' class='icon'/>
      <!-- <email-button id='email-button' class='icon'/> -->
      <!-- <event-button id='event-button' class='icon icon-coming-soon'/> -->
      <!-- <help-button id='help-button' class='icon'/> -->
      <button
        id="logout-button"
        class="btn btn-clear icon"
        data-bs-toggle="modal"
        data-bs-target="#confirmLogoutModal"
      >
        <img src="./assets/logout_icon.svg" alt="" class="logout-icon">
      </button>
      <locale-button id='locale-button' class='icon'/>
    </div>
    
      <!-- 
      <chat-button id='chat-button'/>
       -->
  </div>

  <!-- Logout Confirm Modal -->
  <div
    class="modal fade"
    id="confirmLogoutModal"
    tabindex="-1"
    aria-labelledby="confirmLogoutModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmLogoutModalLabel">{{ t('page.logout') }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{t('logout_prompt')}}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{t('option.logout_no')}}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
            @click="logout"
          >
            {{t('option.logout_yes')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeButton from "./home-button.vue";
// import ChartButton from "./chart-button.vue";
// import MultiUserButton from "./multi-user-button.vue";
// import EmailButton from "./email-button.vue";
import SettingButton from "./setting-button.vue";
// import EventButton from "./event-button.vue";
// import ChatButton from "./chat-button.vue";
// import HelpButton from "./help-button.vue";
import localeButton from "./locale-button.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useI18n } from 'vue-i18n';
import { newUserManager } from "../../user/company-user-manager.ts";

export default {
  setup() {
    const { t, locale } = useI18n()
    return {
      t, locale,
    }
  },
  methods: {
    async logout() {
      await newUserManager.logout();
      this.$router.push("/");
    },
  },
  components: {
    HomeButton,
    // ChartButton,
    // MultiUserButton,
    // EmailButton,
    // EventButton,
    // ChatButton,
    SettingButton,
    // HelpButton,
    localeButton,
  },
};
</script>

<style scoped>
.sidenav {
  position: fixed;
  width: 150px;
  height: 100%;
  left: 0px;
  top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  z-index: 3;
}
#side-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
#company-logo {
  position: relative;
  padding: 10px;
  /* margin: top right bottom left */
  width: 100%;
  background-position: center;
}
.icon {
  /* margin: top right bottom left */
  align-items: center;
  height: fit-content;
}
.icon-coming-soon {
  opacity: 0.5;
}
#logout-button {
  position: relative;
  margin: auto auto;
  padding: 0;
}
#locale-button {
  position: fixed;
  bottom: 2%;
  left: 13px;
  margin: auto auto;
}
.logout-icon {
  width: 80px;
  height: 80px;
}
</style>