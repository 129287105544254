<template>
  <div class="condition-list flex-wrap justify-content-center">
    <p class="condition w-30 text-right">
      <condition-icon class="icon" :fulfilled="conditions.distance_far && conditions.distance_close" />
      {{t('condition_checking.conditions.distance')}}
    </p>
    <p class="condition w-30">
      <condition-icon class="icon" :fulfilled="conditions.brightness" />
      {{t('condition_checking.conditions.lighting')}}
    </p>
    <p class="condition w-30">
      <condition-icon class="icon" :fulfilled="conditions.centered" />
      {{t('condition_checking.conditions.centered')}}
    </p>
    <p class="condition w-40">
      <br> 
      <condition-icon class="icon" :fulfilled="conditions.movement" />
      {{t('condition_checking.conditions.movement')}}
    </p>
    <p class="condition w-40">
      <condition-icon class="icon" :fulfilled="conditions.serverReady" />
      {{t('condition_checking.conditions.server_ready')}}
    </p>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import ConditionIcon from "./condition-icon.vue";

export default {
  name: "conditions-hud",
  props: ["conditions"],
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  computed: {
    isOK() {
      return Object.values(this.conditions).reduce(
        (previousValue, currentValue) => {
          return previousValue && currentValue;
        },
        true
      );
    },
  },
  components: {
    ConditionIcon,
  },
};
</script>

<style scoped>
.condition {
  display: flex;
  flex-direction: row;
  padding-right: 40px;
  text-align: center;
  font-weight: 400;
  font-size: 50px;
  align-items: center;

  color: #484848;
}
.condition-list {
  display: flex;
  flex-direction: row;
  line-height: 75px;
}
.w-30 {
  width: 30%;
  justify-content: center;
}
.w-40 {
  width: 40%;
  justify-content: center;
}
</style>
