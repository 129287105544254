<template>
  <div class="home-page">
    <!-- button navigation -->
    <div class="d-flex buttons-container justify-content-end">
      <locale-button class="locale-button"/>
    </div>


    <div 
      :class="'title ' + titleAnimation" 
      v-html="t('start_page.title')"
    >
    </div>

    <div  class="background-container" >
        <background-circle 
          :showModal="showModal" 
          :scanFinished="scanFinished"
          :state="vsStatus"
          :showResearchContent="showResearchContent"
          :showResult="showResult"
        />
    </div>

    <!-- popup user profile -->
    <user-profile-input 
      v-if="form && showModal" 
      :showModal="showModal" 
      @updateProfile="updateProfile" 
      :profileFilled="profileFilled" 
      :form="form"
      @updateModal="updateModal"
      />

    <!-- video container -->
    <div id="video-container" ref="videoContainer">
      <!-- condition checking & instructions -->
      <div>
        <face-detection-view
          :enable="starting"
          :vsStatus="vsStatus"
          @finishMeasurement="finishMeasure"
          @updateTimeLeft="updateTimeLeft"
          @detectionResult="updateDetectionResult"
          @scanParameters="updateScanParameters"
          :device="devices.length > 1 ? devices[cameraIndex].id : ''"
          @updateCountdown="updateCountdown"
          :countdown="countdown"
          @updateConditions="updateConditions"
          @uploadedScanResult="uploadedScanResult"
        />
      </div>
    </div>
      <div class="start-button">
        <vital-sign-button 
          :enable="detectionResult"
          @startMeasurement="$emit('startMeasurement')"
          @removeProfile="removeProfile"
          @updateModal="updateModal"
          v-if="vsStatus !== 'measuring' && starting && !showModal && !scanFinished"
        />
      </div>

      <condition-checking 
        :conditions="conditions" 
        :countdown="countdown"
        v-if="vsStatus == 'measuring' && starting && !showModal && !timeLeft"
      />
      <!-- <img 
          v-if="devices && devices.length > 1 && vsStatus !== 'measuring' && starting && !showQuiz && !showModal"
          :disabled="vitalSign !== undefined" 
          class="btn camera-switch" 
          @click="switchCamera" 
          src="./assets/switch-camera.svg"> -->
      <!-- <quiz v-if="showQuiz" :scanFinished="scanFinished" :number="number" @displayResult="displayResult" @nextCard="nextCard"/> -->

      <scan-completed-button 
        v-if="scanFinished && !showResult" 
        @updateShowResult="updateShowResult"
      />
      <result
        v-if="scanFinished && showResult && !showResearchContent"
        @updateShowResearchContent="updateShowResearchContent"
        @updateShowResult="updateShowResult"
        @removeProfile="removeProfile"
        :vitalSign="vitalSign"
        :from="showResultFrom"
      />
      <research-content 
        v-if="scanFinished && showResult && showResearchContent"
        @updateShowResearchContent="updateShowResearchContent"
        @updateShowResult="updateShowResult"
        @removeProfile="removeProfile"
      />
      <circular-progress 
        :timeLeft="getTimeLeft()"
        :radius="radius"
        :stroke="20"
        />
      <div class="watermark"
        v-if="!(scanFinished && showResult && showResearchContent)"
      >
        <img src="./assets/watermark.png" >
      </div>
  </div>
</template>

<script>
import Logger from "../../common/logger.js";
import { ref, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import api from "../../common/api.js";
import adapter from "webrtc-adapter";
import FaceDetectionView from "./face-detection-view.vue";
import userProfileInput from "./user-profile-input.vue"
import { newUserManager } from "../../user/company-user-manager.ts";
import LocaleButton from "../sidebar/locale-button.vue"
import ConditionChecking from "./condition-checking.vue";
import CircularProgress from "./circular-progress.vue";
import VitalSignButton from "./components/vital-sign-button-js.vue";
import ScanCompletedButton from './components/scan-completed-button.vue';
import Result from "./result.vue";
import ResearchContent from './research-content.vue';
import BackgroundCircle from './components/background-circle.vue';

export default {
  name: "App",
  props: {
    simulation: Boolean,
  },
  setup() {
    const { idle, updateIdle } = inject("idle");
    const { profileFilled, updateProfileFilled } = inject("profileFilled");
    const { t, locale } = useI18n();
    const router = useRouter();

    var currentUser = newUserManager.currentUser ? newUserManager.currentUser.currentUser : null;
    let vitalSign = null;
    let scanParameters = null;
    if (newUserManager.currentUser && newUserManager.currentUser.cloudInfo) {
      vitalSign = currentUser.lastVitalSigns;
      scanParameters = currentUser.lastHealth ? currentUser.lastHealth.scanParameters : null;
    } else {
      router.push("/");
    }

    return {
      t,
      locale,
      router,
      starting: ref(idle),
      stopping: ref(true),
      sysStatus: ref("standby"),
      vsStatus: ref("idle"),
      flask_fps: ref(),
      vitalSign: ref(vitalSign),
      scanParameters: ref(scanParameters),
      conditions: ref(),
      message: ref(),
      disclaimer: ref(),
      pythonBackend: ref(false),
      timeLeft: ref(null),
      user: ref(currentUser),
      idle,
      updateIdle,
      showTutorial: ref(false),
      profileFilled,
      updateProfileFilled,
    };
  },
  mounted() {
    // console.log(newUserManager.currentUser)
    // console.log(newUserManager.currentUser.currentUser)
    // console.log(newUserManager.currentUser.currentUser.getUserSettings())
    // load user / customer form

    setTimeout(async () => {
      let currentUser = newUserManager.currentUser.currentUser
      if (currentUser) {
        this.form = await currentUser.getUserSettings();
      }
    }, 50);
  }, 
  async created() {
    await this.getUserType();
    if (window.sessionStorage.userSettings) {
      this.form = JSON.parse(window.sessionStorage.userSettings);
    }
  }, 
  watch: {
    locale(locale) {
      if (locale == 'EN') {
        this.fontFamily = "Mulish";
      } else if (locale == "中") {
        this.fontFamily = 'Open-Sans'
      }
    },  
    showTitle: {
      handler: function (val) {
        if (val.vsStatus !== 'measuring' && val.starting && !val.showModal && !val.showResult) {
          this.titleAnimation = 'show-title';
        } else {
          this.titleAnimation = 'hide-title';
        }
      },
      deep: true
    },
    vsStatus(newValue) {
      if (newValue == "measuring") {
        this.conditions = undefined;
        this.message = undefined;
      }
    },
    devices() {
      if (this.devices[0].text !== '') {
        clearInterval(this.checkDevice);
      }
    },
    sysStatus(status) {
      Logger.info(`Status changed from "${this.sysStatus}" to "${status}".`);
      if (status == "running") {
        this.starting = false;
        this.message = undefined;
      }

      if (status == "standby") {
        this.stopping = false;
        this.message = undefined;
      }
    },
    healthObject: {
        deep: true,
        handler: function() {
            this.vitalSign = newUserManager.currentUser.currentUser.lastVitalSigns;
        }
    },
    async starting(val) {
      if(val) {
        this.checkDevice = setInterval(async () => {
          let devices = await navigator.mediaDevices.enumerateDevices();
          devices = devices.filter(device => device.kind === 'videoinput');
          this.devices = devices.map((device, index) => {
            return {text: device.label, id: device.deviceId, deviceIndex: index}
          })
        }, 1000);
      } else if(!val) {
        this.stopSystem();
      }
    },
  },
  data() {
    return {
      detectionResult: undefined,
      aspectRatio: undefined,
      form: undefined,
      userType: undefined,
      scanFinished: false,
      number: 0,
      cameraIndex: 0,
      devices: [{text: '', id: '', deviceIndex: 0}],
      checkDevice: undefined,
      countdown: 3,
      showResult: false,
      showModal: false,
      scanResultId: '',
      showResearchContent: false,
      titleAnimation: '',
      showResultFrom: "",
      fontFamily: "Mulish"
    };
  },
  methods: {
    updateShowResearchContent(value) {
      this.showResearchContent = value;
    },
    updateShowResult(value, from) {
      this.showResult = value;
      this.showResultFrom = from;
    },
    updateShowTC(value) {
      this.showTC = value;
    },
    uploadedScanResult(id) {
      this.scanResultId = id;
    },
    updateModal(value) {
      this.showModal = value;
    },
    updateConditions(conditions) {
      this.conditions = conditions;
    },
    updateCountdown(countdown) {
      this.countdown = countdown;
    },
    switchCamera() {
      this.cameraIndex = (this.cameraIndex + 1) % this.devices.length;
    },
    async getUserType() {
      setTimeout(() => {       
        if (!this.currentUser) return;
        else {
          this.userType = this.currentUser.userSettings.planType;
        }
      }, 2000);
    },
    showGuide() {
      this.showTutorial = !this.showTutorial;
    },
    startMeasurement() {
      // check conditions
      this.vitalSign = null;
      this.scanParameters = null;
      if ( newUserManager.currentUser ) {
        let currentUser = newUserManager.currentUser.currentUser
        if ( currentUser ) { currentUser.clearHealth(); }
      }
      this.vsStatus = "measuring";
      this.scanFinished = false;
    },
    updateTimeLeft(newValue) {
      this.timeLeft = newValue;
      // console.log("home-page time left: ", this.timeLeft);
    },
    getTimeLeft() {
      return this.timeLeft;
    },
    finishMeasure() {
      this.vsStatus = "idle";
      this.scanFinished = true;
    },
    updateDetectionResult(newValue) {
      this.detectionResult = newValue;
    },
    updateScanParameters(newValue) {
      this.scanParameters = newValue;
    },
    getAspectRatio(ar) {
      this.aspectRatio = ar;
    },
    onResult(result) {
      this.detectionResult = result;
    },
    refreshStatus() {
      api.refreshStatus().then((data) => {
        this.flask_fps = data.fps;

        if (data.status == "running") {
          this.starting = false;
        }

        if (data.status == "standby") {
          this.stopping = false;
        }

        if (this.sysStatus !== data.status) {
          Logger.info(
            `Status changed from "${this.sysStatus}" to "${data.status}".`
          );
        }

        this.sysStatus = data.status;
      });
    },
    startSystem: function() {
      Logger.info("Start button pressed");

      // this.enableSound();

      this.updateIdle();
      this.starting = true;
      this.stopping = false;
      this.conditions = undefined;
      this.vitalSign = undefined;

      if (this.pythonBackend) {
        api
          .startSystem()
          .then(() => {
            this.refreshStatus();
          })
          .catch((err) => {
            console.error(err);
            alert(err.message);
            this.starting = false;
          });
        return false;
      } else {
        //this.sysStatus = "running";
        return false;
      }
    },
    stopSystem: function() {
      Logger.info("Stop button pressed.");

      this.updateIdle();
      this.stopping = true;
      this.starting = false;
      this.vitalSign = undefined;
      this.conditions = undefined;
      this.detectionResult = undefined;
      this.vsStatus = 'idle';

      if (this.pythonBackend) {
        api.stopSystem().then(() => {
          this.starting = false;
          this.stopping = true;
          this.refreshStatus();
        });
        return false;
      } else {
        this.sysStatus = "standby";
        return false;
      }
    },
    fullScreen() {
      this.$refs.videoContainer.requestFullscreen();
    },
    async removeProfile() {
      await newUserManager.currentUser.currentUser.clearUserSettings();
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(false);
      this.vitalSign = null;
      this.scanParameters = null;
      this.scanFinished = false;
    },
    async updateProfile(form) {
      await newUserManager.currentUser.currentUser.setUserSettings(form)
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(true);
      this.startMeasurement();
    },
  },
  computed: {
    showTitle() {
      const {vsStatus, starting, showModal, showResult} = this;
      return {
        vsStatus, starting, showModal, showResult
      }
    },
    on() {
      return '"' + this.t('button.on') + '"';
    },
    healthObject() {
        if (this.$root.$data.healthObject !== undefined) {
            return "healthObject updated."
        } else {
            return "no health result"
        }
    },
    radius() {
      if (window.innerWidth < 834) {
        return 48
      }
      else {
        return 63.5
      }
    },
    gap() {
      if (adapter.browserDetails.browser == "safari") {
        return "20px";
      } else {
        return 0;
      }
    },
  },
  components: {
    FaceDetectionView,
    userProfileInput,
    Result,
    LocaleButton,
    ConditionChecking,
    CircularProgress,
    VitalSignButton,
    ScanCompletedButton,
    ResearchContent,
    BackgroundCircle
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500&family=Open+Sans:wght@400;700&display=swap');
html {
  scroll-behavior: smooth;
}
body {
  background-color: lightgray;
  max-height: 100vw !important;
  min-height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;
}
button {
  border: none;
}
</style>

<style scoped>
.home-page {
  font-family: v-bind(fontFamily);
}
.background-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: hidden;
}

.start-button {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 1150px;
} 

.title {
  position: absolute;
  left: 134px;
  top: 127px;
  width: 1100px;
  font-weight: 400;
  font-size: 200px;

  z-index: 5;
  color: #FFFFFF;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.show-title {
  animation: fade-in 1s ease 1 forwards;
}

@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.hide-title {
  animation: fade-out .5s ease 1 forwards;
}

@keyframes fade-out {
  0% {opacity: 1;}
  100% {opacity: 0; z-index: 0;}
}

.watermark {
  position: absolute;
  bottom: 121px;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;
}

.camera-switch {
  transform: scale(0.8);
  position: absolute;
  bottom: 5%;
  right: 3%;
}

.switch-button {
  background: rgba(255, 255, 255, 0.56);
  border-radius: 20px;
  overflow: hidden;
  width: 200px;
  height: 36px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color: black;
  padding-left: 100px;
  position: relative;
}
.switch-button:before {
  content: v-bind(on);
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width:100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}

.white {
  color: white;
}

.black {
  color: black;
}

.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(-100px);
  transition: transform 300ms linear;
  background-color: #A5915C;
}

.switch-button-checkbox + .switch-button-label {
  position: relative;
  display: block;
  user-select: none;
  pointer-events: none;
  line-height: 36px;
}

.switch-button-label:before {
  content: "";
  background: white;
  height: 36px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color:black;
  border-radius: 20px;
  transform: translateX(0);
  transition: transform 300ms;
  
}

.switch-button-label-span {
  position: relative;
  color: black;
  font-size: 17px;
  font-weight: 600;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.quick-help-button {
    /* display: inline-block; */
    opacity: 0.7;
    min-width: 40px;
    min-height: 40px;
    vertical-align: middle;
    z-index: 2;
}
.buttons {
  vertical-align: middle;;
  position: relative;
  z-index: 1;
}
.buttons-container {
  display: flex;
  position: absolute;
  right: 111px;
  bottom: 1507px;
  vertical-align: middle;;
  z-index: 3;
  gap: 20px;
}
.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.vital-sign-hud {
  margin-left: 48px;
  margin-right: 48px;
}
.vsButton {
  display: flex;
  justify-content: center;
}
#lify-logo {
  position: absolute;
  width: 135px;
  top: 80px;
  left: 80px;
}

#panoptic-logo {
  position: absolute;
  width: 280px;
  left: 230px;
  top: 126px;
}

#fittery-logo {
  position: absolute;
  width: 135px;
  height: 135px;
  left: 530px;
  top: 80px;
}

#tda-logo {
  position: absolute;
  left: 698px;
  top: 117px;
}

.system-message {
  margin-top: 20%;
}
#video-container {
  overflow: hidden;
  z-index: 0;
  height: 100vh;
}
.running-message {
  margin-top: 20%;
  font-size: 48px;
  color: white;
  text-align: center;
  text-shadow: 0px 0px 5px #000000;
}
.disclaimer {
  position: absolute;
  left: 40%;
  right: 40%;
  top: 20px;
  padding: 10px;
  color: black;
  background-color: lightgrey;
  opacity: 0.8;
  font-size: 0.9em;
  text-align: center;
}
.copyright {
  position: relative;
  font-size: 14px;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 0);
}
#icon-mobile {
  display: none;
}
.huds-container {
  position: absolute;
  top: 208px;
  width: 100%;
  height: 70%;
  overflow-y: scroll;
  overscroll-behavior: none;
  /* height: fit-content; */
}
.huds-container::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.huds-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.disableBox {
  pointer-events: none;
  opacity: 0.7;
}
.info-bar {
  position: absolute;
  bottom: 5%;
  left: calc(50% - 64px)
}
.info-button {
  width: 150px;
  height: 75px;
  min-height: 50px;
  font-size: large;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: auto;
  margin-bottom: auto;
}

.vitals-row {
  margin-left: 3rem;
  margin-right: 3rem;
}

@media (max-width: 2000px) {
  .huds-container {
    top: 500px;
    height: 45%;
  }
  #lify-logo {
    width: 100px;
    top: 40px;
    left: 38px;
  }
  #panoptic-logo {
    width: 200px;
    left: 150px;
    top: 75px;
  }
  #fittery-logo {
    width: 100px;
    height: 100px;
    left: 365px;
    top: 40px;
  }
  #tda-logo {
    width: 140px;
    left: 480px;
    top: 65px;
  }
}

@media (max-width: 940px) {
  #lify-logo {
    width: 70px;
    top: 30px;
    left: 38px;
  }
  #panoptic-logo {
    width: 175px;
    left: 120px;
    top: 55px;
  }
  #fittery-logo {
    width: 70px;
    height: 70px;
    left: 305px;
    top: 30px;
  }
  #tda-logo {
    width: 110px;
    left: 385px;
    top: 47px;
  }
}

@media (max-width: 720px) {
  .buttons {
    right: 20px;
  }

  .huds-container {
    top: 350px;
  }

  .vitals-row {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .info-bar {
    left: calc(50% - 48px);
  }
}
</style>
